import { Auth0Provider } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { page_getAll } from "api/page.api";
import UserProfileDialog from "components/UserProfile/UserProfileDialog";
import { ProvideUser } from "hooks/User";
import { PageList } from "interface/page.interface";
import { SnackbarProvider } from "notistack";
import { handleDisplayPages } from "pages";
import { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import theme from "./../config/theme";
import CornerFab from "./common/components/CornerFab";
import Footer from "./common/components/Footer";
import Navigation from "./common/components/Navigation";

const Root: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [pages, setPages] = useState<PageList[]>([]);
    const [openProfile, setOpenProfile] = useState<boolean>(false);

    const handleGetPageList = async () => {
        const response = await page_getAll();
        if (response.status === "success") {
            const pageList = response.data;
            setPages(handleDisplayPages(pageList));
        }
    };

    const handleOpenProfile = () => setOpenProfile(true);
    const handleCloseProfile = () => setOpenProfile(false);

    useEffect(() => {
        if (location.pathname === "/") navigate("/home");
    }, [location]);

    useEffect(() => {
        handleGetPageList();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Auth0Provider
                domain="dev-ua2rdkj5.us.auth0.com"
                clientId="ekCxiG85IpkK5tXsMQ12ww6wPfrS0DI6"
                authorizationParams={{
                    redirect_uri: window.location.origin,
                }}
            >
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                    <ProvideUser>
                        <Box
                            id="main"
                            sx={{
                                minHeight: "100%",
                                width: "100%",
                                backgroundColor: theme.palette.background.default,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                        >
                            <Navigation pages={pages} handleOpenProfile={handleOpenProfile} />
                            <Box
                                sx={{
                                    pt: "64px",
                                    height: "calc(100% - 64px)",
                                    minHeight: "calc(100% - 64px)",
                                    width: "100%",
                                    flexGrow: 1,
                                }}
                            >
                                <Outlet />
                                <CornerFab />
                            </Box>
                            <Footer pages={pages} />
                            <UserProfileDialog open={openProfile} close={handleCloseProfile} />
                        </Box>
                    </ProvideUser>
                </SnackbarProvider>
            </Auth0Provider>
        </ThemeProvider>
    );
};

export default Root;
