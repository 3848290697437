import { Box, Typography } from "@mui/material";
import { OrderPopulated } from "interface/order.interface";
import { FC, MouseEvent, useState } from "react";
import QuickMenu from "../components/QuickMenu";
import SimpleRow from "./SimpleRow";

interface Props {
    orders: OrderPopulated[];
    edit: (order: OrderPopulated) => void;
    deleteItem: (id: string) => void;
    update: () => void;
}

const OrderSimpleView: FC<Props> = (props: Props) => {
    const { orders, edit, deleteItem, update } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentStatus, setCurrentStatus] = useState<string>("");
    const [selectedId, setSelectedId] = useState<string>("");

    const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>, status: string, id: string) => {
        setAnchorEl(e.currentTarget);
        setCurrentStatus(status);
        setSelectedId(id);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentStatus("");
    };

    return orders ? (
        <Box>
            {orders.map((order) => (
                <SimpleRow key={order.id} order={order} edit={edit} deleteItem={deleteItem} openMenu={handleOpenMenu} />
            ))}
            <QuickMenu
                anchorEl={anchorEl}
                close={handleCloseMenu}
                currentStatus={currentStatus}
                id={selectedId}
                update={update}
            />
        </Box>
    ) : (
        <Box>
            <Typography variant="body1">No current orders available.</Typography>
        </Box>
    );
};

export default OrderSimpleView;
