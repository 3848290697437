import { Box } from "@mui/material";
import React, { FC } from "react";
import homeBanner from "../../../images/home-banner.jpg";
import drillingBanner from "../../../images/drilling-banner.jpg";
import maintBanner from "../../../images/maintenance-banner.jpg";
import coachBanner from "../../../images/coaching-banner.jpg";
import shopBanner from "../../../images/shop-banner.jpg";
import staffBanner from "../../../images/staff-banner.jpg";
import contactBanner from "../../../images/contact-banner.jpg";

interface PageBannerProps {
    image: string;
}

const PageBanner: FC<PageBannerProps> = (props: PageBannerProps) => {
    const { image } = props;

    const getImage = (name: string) => {
        if (name === "home") return homeBanner;
        else if (name === "drilling") return drillingBanner;
        else if (name === "maintenance") return maintBanner;
        else if (name === "coaching") return coachBanner;
        else if (name === "shop") return shopBanner;
        else if (name === "staff") return staffBanner;
        else if (name === "contact") return contactBanner;
    };

    return (
        <Box sx={{ width: "100%", height: 300, p: 0, m: 0, textAlign: "center", overflow: "hidden" }}>
            <Box
                component="img"
                src={getImage(image)}
                sx={{ height: "100%", marginLeft: "50%", transform: "translate(-50%, 0)" }}
            />
        </Box>
    );
};

export default PageBanner;
