import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { updatedHours_getWeek } from "api/updatedHours.api";
import { UpdatedHours } from "interface/updatedHours.interface";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";

const UpdatedHoursTable: FC = () => {
    const [updatedHours, setUpdatedHours] = useState<UpdatedHours[]>([]);

    const snack = useSnack();
    const theme = useTheme();

    const handleGetWeeksUpdatedHours = async () => {
        const response = await updatedHours_getWeek();
        if (response.status === "success") {
            setUpdatedHours(response.data!);
        } else {
            snack("error", "Error getting Hours. " + response.message!);
        }
    };

    useEffect(() => {
        handleGetWeeksUpdatedHours();
    }, []);

    return (
        updatedHours && (
            <Table>
                <TableBody>
                    {updatedHours.length ? (
                        updatedHours.map((day) => (
                            <TableRow id={day.date} key={day.date}>
                                <TableCell
                                    component="th"
                                    sx={{
                                        fontWeight: moment().format("YYYY-MM-DD") === day.date ? "bold" : "normal",
                                    }}
                                >
                                    {moment().format("YYYY-MM-DD") === day.date
                                        ? "Today"
                                        : moment(day.date).format("MM-DD-YYYY")}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: moment().format("YYYY-MM-DD") === day.date ? "bold" : "normal",
                                    }}
                                >
                                    {day.closed ? (
                                        <span style={{ color: "red" }}>Closed</span>
                                    ) : (
                                        `${day.open} - ${day.close}`
                                    )}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <Typography variant="body1" sx={{ mt: theme.spacing(3) }}>
                            No scheduled changes.
                        </Typography>
                    )}
                </TableBody>
            </Table>
        )
    );
};

export default UpdatedHoursTable;
