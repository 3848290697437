import { Box, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { page_getByPath } from "api/page.api";
import { PagePopulated } from "interface/page.interface";
import { FC, useEffect, useState } from "react";
import AnnouncementCard from "routes/common/components/AnnouncementCard";
import Loading from "routes/common/components/Loading";
import useSnack from "routes/common/components/ProvideSnack";
import TopCard from "routes/common/components/TopCard";
import laneImage from "../../SSO-BowlingLane.jpg";
import FacebookPage from "./components/FacebookPage";
import HomeCard from "./components/HomeCard";

const Home: FC = () => {
    const theme = useTheme();
    const snack = useSnack();

    const [pageData, setPageData] = useState<PagePopulated | null>(null);

    const loadPageData = async () => {
        const response = await page_getByPath("home");
        if (response.status === "success") {
            setPageData(response.data!);
        } else {
            snack("error", response.status + ": " + response.message);
        }
    };

    useEffect(() => {
        loadPageData();
    }, []);

    return pageData ? (
        <Box
            sx={{
                backgroundImage: `url(${laneImage})`,
                backgroundSize: "cover",
            }}
        >
            {pageData.announcementText ? (
                <AnnouncementCard title={pageData.announcementTitle} text={pageData.announcementText} />
            ) : null}
            <Box sx={{ display: "flex", flexFlow: "row no-wrap", justifyContent: "center", alignItems: "flex-start" }}>
                <Box sx={{ display: { xs: "none", md: "block" }, m: theme.spacing(2) }}>
                    <FacebookPage height={900} width={350} />
                </Box>
                <Container maxWidth="md" sx={{ mt: theme.spacing(2) }}>
                    {pageData.bannerText ? <TopCard text={pageData.bannerText} /> : null}
                    <Box
                        sx={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(200, 200, 200, 0.8)",
                            borderRadius: { sx: 0, sm: 4 },
                            minHeight: 500,
                            mt: theme.spacing(2),
                            mb: theme.spacing(2),
                        }}
                    >
                        {pageData.Cards ? pageData.Cards.map((card) => <HomeCard key={card.id} card={card} />) : null}
                    </Box>
                </Container>
            </Box>
        </Box>
    ) : (
        <Loading />
    );
};

export default Home;
