import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { footerLink_patch } from "api/footerLink.api";
import { FooterLink, FooterLinkUpdate, TFooterLinkCategory } from "interface/footerLink.interface";
import { FC, useEffect, useMemo, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";

interface Props {
    footerLink: FooterLink;
    open: boolean;
    close: () => void;
    update: () => void;
}

const EditLink: FC<Props> = (props: Props) => {
    const { footerLink, open, close, update } = props;
    const theme = useTheme();
    const snack = useSnack();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    const [display, setDisplay] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const [category, setCategory] = useState<TFooterLinkCategory | "">("");

    const handleSave = async () => {
        const data: FooterLinkUpdate = {
            id: footerLink.id,
            display: display,
            link: link,
            category: category as TFooterLinkCategory,
        };
        const response = await footerLink_patch(data);
        if (response.status === "success") {
            snack("success", "Link updated.");
            close();
            update();
        } else {
            snack("error", "Error updating link.");
        }
    };

    const changes = useMemo(() => {
        if (display !== footerLink.display || link !== footerLink.link || category !== footerLink.category) return true;
        return false;
    }, [display, link, category]);

    const allowSave = useMemo(() => {
        if (display && link && category && link.includes("https://")) return true;
        return false;
    }, [display, link, category]);

    useEffect(() => {
        setDisplay(footerLink.display);
        setLink(footerLink.link);
        setCategory(footerLink.category);
        return () => {
            setDisplay("");
            setLink("");
            setCategory("");
        };
    }, [close]);

    return (
        <Dialog open={open} fullScreen={mobile}>
            <DialogTitle>Edit Footer Link</DialogTitle>
            <DialogContent sx={{ width: mobile ? "auto" : 500, overflowX: "hidden" }}>
                <TextField
                    sx={{ mt: 1 }}
                    value={display}
                    onChange={(e) => setDisplay(e.target.value)}
                    label="Display Text"
                    fullWidth
                    helperText="This will be what the link looks like on the screen"
                />
                <TextField
                    sx={{ mt: 1.5 }}
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    label="Hyperlink"
                    fullWidth
                    helperText="Format: https://example.com"
                />
                <FormControl fullWidth sx={{ mt: 1.5 }}>
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                        labelId="category-label"
                        value={category}
                        label="Category"
                        onChange={(e) => setCategory(e.target.value as TFooterLinkCategory)}
                    >
                        <MenuItem value="social">Social Media</MenuItem>
                        <MenuItem value="affiliate">Affiliate</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <Button onClick={handleSave} disabled={!allowSave || !changes} variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditLink;
