import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { footerLink_getAll } from "api/footerLink.api";
import { useUser } from "hooks/User";
import { FooterLink } from "interface/footerLink.interface";
import { PageList } from "interface/page.interface";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useSnack from "./ProvideSnack";

interface FooterProps {
    pages: PageList[];
}

const Footer: FC<FooterProps> = (props: FooterProps) => {
    const { pages } = props;
    const user = useUser();
    const snack = useSnack();

    const [socialLinks, setSocialLinks] = useState<FooterLink[]>([]);
    const [afflLinks, setAfflLinks] = useState<FooterLink[]>([]);

    const loadLinks = async () => {
        const response = await footerLink_getAll();
        if (response.status === "success") {
            setSocialLinks(
                response.data.filter((link) => link.category === "social").sort((a, b) => a.order - b.order),
            );
            setAfflLinks(
                response.data.filter((link) => link.category === "affiliate").sort((a, b) => a.order - b.order),
            );
        } else {
            snack("error", "Error loading links");
        }
    };

    useEffect(() => {
        loadLinks();
    }, []);

    return (
        <Box sx={{ width: "100%", backgroundColor: "#000", color: "#fff" }}>
            <Grid container spacing={0} sx={{ width: "100%" }}>
                <Grid md={4} xs={12}>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">TenBack</Typography>
                        <Divider sx={{ borderColor: "#fff" }} />
                        <List dense={true}>
                            {pages.map((page) => (
                                <ListItem disablePadding key={page.id}>
                                    <ListItemButton component={Link} to={page.path}>
                                        <ListItemText primary={page.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            {user.profile.admin ? (
                                <ListItem disablePadding>
                                    <ListItemButton component={Link} to={"/admin"}>
                                        <ListItemText primary={"Site Administration"} />
                                    </ListItemButton>
                                </ListItem>
                            ) : null}
                        </List>
                    </Box>
                </Grid>
                <Grid md={4} xs={12}>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">Social Media</Typography>
                        <Divider sx={{ borderColor: "#fff" }} />
                        <List dense={true}>
                            {socialLinks.map((item) => (
                                <ListItem disablePadding key={item.display}>
                                    <ListItemButton component={Link} to={item.link} target="_blank">
                                        <ListItemText primary={item.display} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Grid>
                <Grid md={4} xs={12}>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2">Affiliations</Typography>
                        <Divider sx={{ borderColor: "#fff" }} />
                        <List dense={true}>
                            {afflLinks.map((item) => (
                                <ListItem disablePadding key={item.display}>
                                    <ListItemButton component={Link} to={item.link} target="_blank">
                                        <ListItemText primary={item.display} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Footer;
