import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0288d1",
        },
        secondary: {
            main: "#c62828",
        },
        background: {
            default: "#aaaaaa",
            paper: "#ffffff",
        },
    },
    typography: {
        // fontSize: "1rem",
        h1: {
            fontSize: "3rem",
            fontWeight: "bold",
        },
        h2: {
            fontSize: "2.5rem",
            fontWeight: "bold",
        },
        h3: {
            fontSize: "2.5rem",
            fontWeight: "normal",
        },
        h4: {
            fontSize: "1.5rem",
            fontWeight: "bold",
        },
        h5: {
            fontSize: "1.5rem",
            fontWeight: "normal",
        },
        h6: {
            fontSize: "1.25rem",
            fontWeight: "bold",
        },
        subtitle1: {
            fontSize: "1.25rem",
            fontStyle: "italic",
            fontWeight: "normal",
        },
        subtitle2: {
            fontSize: "0.825rem",
            fontStyle: "italic",
            fontWeight: "normal",
        },
        body1: {
            fontSize: "1rem",
        },
        body2: {
            fontSize: "0.875rem",
        },
    },
});

export default theme;
