import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { user_getById, user_patch } from "api/user.api";
import dayjs from "dayjs";
import { useUser } from "hooks/User";
import { UserUpdate } from "interface/user.interface";
import React, { FC, useEffect, useMemo, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";

interface UserProfileDialogProps {
    open: boolean;
    close: () => void;
}

const UserProfileDialog: FC<UserProfileDialogProps> = (props: UserProfileDialogProps) => {
    const user = useUser();
    const theme = useTheme();
    const snack = useSnack();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const { open, close } = props;
    const [name, setName] = useState<string>("");
    const [birthday, setBirthday] = useState<dayjs.Dayjs | null>(null);
    const [areaCode, setAreaCode] = useState<string>("");
    const [phone1, setPhone1] = useState<string>("");
    const [phone2, setPhone2] = useState<string>("");
    const [textMsgs, setTextMsgs] = useState<boolean>(true);

    const allowSave = useMemo(() => {
        if (name && areaCode && phone1 && phone2 && areaCode.length === 3 && phone1.length === 3 && phone2.length === 4)
            return true;
        else return false;
    }, [name, areaCode, phone1, phone2]);

    const changes = useMemo(() => {
        if (name !== user.profile.name) return true;
        if (areaCode + "-" + phone1 + "-" + phone2 !== user.profile.phone) return true;
        if (textMsgs !== user.profile.textMsgs) return true;
        if (birthday !== dayjs(user.profile.birthday)) return true;
        else return false;
    }, [name, areaCode, phone1, phone2, textMsgs, birthday]);

    const loadProfile = () => {
        setName(user.profile.name);
        setBirthday(user.profile.birthday ? dayjs(user.profile.birthday) : null);
        if (user.profile.phone) {
            const phone = user.profile.phone.split("-");
            setAreaCode(phone[0]);
            setPhone1(phone[1]);
            setPhone2(phone[2]);
        }
        setTextMsgs(user.profile.textMsgs ?? false);
    };

    const handleSave = async () => {
        const data: UserUpdate = {
            id: user.profile.id,
            name: name,
            birthday: birthday?.toString(),
            phone: areaCode && phone1 && phone2 ? areaCode + "-" + phone1 + "-" + phone2 : "",
            textMsgs: textMsgs,
        };
        const response = await user_patch(data);
        if (response.status === "success" && response.data![0] === 1) {
            snack("success", "Profile updated successfully");
            const updatedUser = await user_getById(user.profile.id);
            if (updatedUser.status === "success" && updatedUser.data.id) {
                user.setProfile(updatedUser.data);
                close();
            } else {
                snack("error", "Error retrieving updated profile from server: " + response.message);
            }
        } else {
            snack("error", "Error updating profile: " + response.message);
        }
    };

    const handleClose = () => {
        if (changes) if (!confirm("You have unsaved changes. Still leave this page?")) return;
        close();
        sessionStorage.setItem("profile", "checked");
    };

    const handleChangeAreaCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        const clean = text.replace(/\D/g, "").trim().slice(0, 3);
        setAreaCode(clean);
        if (text.length === 3) {
            document.getElementById("phone-2")?.focus();
        }
    };

    const handleChangePhone1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        const clean = text.replace(/\D/g, "").trim().slice(0, 3);
        setPhone1(clean);
        if (text.length === 3) {
            document.getElementById("phone-3")?.focus();
        } else if (text.length === 0) {
            document.getElementById("phone-1")?.focus();
        }
    };

    const handleChangePhone2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        const clean = text.replace(/\D/g, "").trim().slice(0, 4);
        setPhone2(clean);
        if (text.length === 4) {
            document.getElementById("text-allow")?.focus();
        } else if (text.length === 0) {
            document.getElementById("phone-2")?.focus();
        }
    };

    useEffect(() => {
        loadProfile();
    }, [user]);

    return (
        <Dialog open={open} fullScreen={mobile}>
            <IconButton onClick={handleClose} size="large" sx={{ position: "absolute", right: 0, top: 0 }}>
                <CloseIcon color="error" sx={{ fontSize: 24 }} />
            </IconButton>
            <DialogTitle>User Profile</DialogTitle>
            <DialogContent>
                <Typography>
                    Please take a moment to make sure your profile is up to date! We do not share your personal data
                    with anyone without prior permission.
                </Typography>
                <Divider sx={{ m: theme.spacing(2) }} />
                <InputLabel required>Name</InputLabel>
                <TextField value={name} onChange={(e) => setName(e.target.value)} sx={{ m: theme.spacing(1) }} />
                <InputLabel required>Email</InputLabel>
                <Typography sx={{ m: theme.spacing(1), fontWeight: "bold" }}>{user.profile.email}</Typography>
                <Divider sx={{ m: theme.spacing(2) }} />
                <InputLabel required>Phone Number</InputLabel>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography fontSize={24}>(</Typography>
                    <TextField
                        id="phone-1"
                        size="small"
                        value={areaCode}
                        onChange={handleChangeAreaCode}
                        sx={{ width: 60, m: theme.spacing(1) }}
                        error={areaCode.length < 3}
                    />
                    <Typography fontSize={24}>)</Typography>
                    <TextField
                        id="phone-2"
                        size="small"
                        value={phone1}
                        onChange={handleChangePhone1}
                        sx={{ width: 60, m: theme.spacing(1) }}
                        error={phone1.length < 3}
                    />
                    <Typography fontSize={24}>-</Typography>
                    <TextField
                        id="phone-3"
                        size="small"
                        value={phone2}
                        onChange={handleChangePhone2}
                        sx={{ width: 80, m: theme.spacing(1) }}
                        error={phone2.length < 4}
                    />
                </Box>
                <FormHelperText>Enter 10-digit Phone Number</FormHelperText>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="text-allow"
                            checked={textMsgs}
                            onChange={(e) => {
                                setTextMsgs(e.target.checked);
                            }}
                        />
                    }
                    label="Allow text notifications"
                />
                <FormHelperText>Some carriers may charge for text messages based on your service plan</FormHelperText>
                <Divider sx={{ m: theme.spacing(2) }} />
                <InputLabel sx={{ mb: theme.spacing(1) }}>Birthday (optional)</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={birthday}
                        onChange={(newValue) => {
                            setBirthday(newValue);
                        }}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{changes ? "Cancel" : "Close"}</Button>
                <Button variant="contained" disabled={!changes || !allowSave} onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserProfileDialog;
