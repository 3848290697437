import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import { useUser } from "hooks/User";
import { FC, Fragment, MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";

interface Props {
    handleOpenProfile: () => void;
}

const DesktopUserDisplay: FC<Props> = (props: Props) => {
    const { user: authUser } = useAuth0();
    const user = useUser();
    const { handleOpenProfile } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

    const handleCloseMenu = () => setAnchorEl(null);

    return (
        <Fragment>
            <IconButton onClick={handleOpenMenu}>
                <Avatar src={authUser?.picture} color="primary">
                    {authUser?.name?.slice(0, 2)}
                </Avatar>
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                <MenuItem onClick={handleOpenProfile}>Your Profile</MenuItem>
                {user.profile.admin && (
                    <MenuItem component={Link} to="/admin">
                        Admin Page
                    </MenuItem>
                )}
                <MenuItem>
                    <LogoutButton />
                </MenuItem>
            </Menu>
        </Fragment>
    );
};

export default DesktopUserDisplay;
