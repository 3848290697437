import { API_URL } from "config/api";
import { Card, CardCreate, CardMove, CardUpdate } from "interface/card.interface";

export const CARD_URL = `${API_URL}/card`;

export const card_getAll = async (): Promise<{ status: string; data?: Card[]; message: string }> => {
    const response = await fetch(`${CARD_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const card_getAvailable = async (id: string): Promise<{ status: string; data?: Card[]; message: string }> => {
    const response = await fetch(`${CARD_URL}/available/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const card_getById = async (id: string): Promise<{ status: string; data?: Card; message: string }> => {
    const response = await fetch(`${CARD_URL}/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const card_post = async (
    newDocument: CardCreate,
): Promise<{ status: string; data?: Card; message?: string }> => {
    const response = await fetch(`${CARD_URL}`, {
        method: "POST",
        body: JSON.stringify(newDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const card_patch = async (
    updatedDocument: CardUpdate,
): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${CARD_URL}`, {
        method: "PATCH",
        body: JSON.stringify(updatedDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const card_move_patch = async (
    data: CardMove,
): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${CARD_URL}/move`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const card_delete = async (id: string): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${CARD_URL}`, {
        method: "DELETE",
        body: JSON.stringify({ id: id }),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};
