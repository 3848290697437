import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { PageList } from "interface/page.interface";
import React, { FC } from "react";
import { Link } from "react-router-dom";

interface DrawerNavItemProps {
    page: PageList;
}

const DrawerNavItem: FC<DrawerNavItemProps> = (props: DrawerNavItemProps) => {
    const { page } = props;

    return (
        <ListItem key={page.name} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} component={Link} to={page.path}>
                <ListItemText primary={page.name} />
            </ListItemButton>
        </ListItem>
    );
};

export default DrawerNavItem;
