import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC } from "react";

interface CancelButtonProps {
    onClick: () => any;
}

const CancelButton: FC<CancelButtonProps> = (props: CancelButtonProps) => {
    const { onClick } = props;
    const theme = useTheme();

    return (
        <Button
            color="secondary"
            variant="outlined"
            onClick={onClick}
            startIcon={<CancelIcon />}
            sx={{ mr: theme.spacing(1) }}
        >
            Cancel
        </Button>
    );
};

export default CancelButton;
