import { Menu, MenuItem, Typography } from "@mui/material";
import { order_patch } from "api/order.api";
import dayjs from "dayjs";
import { useUser } from "hooks/User";
import { OrderUpdate } from "interface/order.interface";
import { FC, Fragment } from "react";
import useSnack from "routes/common/components/ProvideSnack";

interface Props {
    anchorEl: HTMLButtonElement | null;
    close: () => void;
    currentStatus: string;
    id: string;
    update: () => void;
}

const QuickMenu: FC<Props> = (props: Props) => {
    const { anchorEl, close, currentStatus, id, update } = props;
    const snack = useSnack();
    const user = useUser();

    const handleChangeStatusToOrdered = async () => {
        const data: OrderUpdate = {
            id: id,
            status: "Ordered",
            orderedDate: dayjs().toString(),
            UpdatedById: user.profile.id,
        };
        const response = await order_patch(data);
        if (response.status === "success") {
            snack("success", "Updated order!");
            update();
            close();
        } else {
            snack("error", "Error updating order. " + response.message);
        }
    };

    const handleChangeStatusToReceived = async () => {
        const data: OrderUpdate = {
            id: id,
            status: "Received",
            receivedDate: dayjs().toString(),
            UpdatedById: user.profile.id,
        };
        const response = await order_patch(data);
        if (response.status === "success") {
            snack("success", "Updated order!");
            update();
            close();
        } else {
            snack("error", "Error updating order. " + response.message);
        }
    };

    const handleChangeStatusToPickedUp = async () => {
        const data: OrderUpdate = {
            id: id,
            status: "Complete",
            archived: true,
            pickedUpDate: dayjs().toString(),
            UpdatedById: user.profile.id,
        };
        const response = await order_patch(data);
        if (response.status === "success") {
            snack("success", "Updated order!");
            update();
            close();
        } else {
            snack("error", "Error updating order. " + response.message);
        }
    };

    const handleChangeStatusToCancelled = async () => {
        const data: OrderUpdate = {
            id: id,
            status: "Cancelled",
            archived: true,
            UpdatedById: user.profile.id,
        };
        const response = await order_patch(data);
        if (response.status === "success") {
            snack("success", "Updated order!");
            update();
            close();
        } else {
            snack("error", "Error updating order. " + response.message);
        }
    };

    const handleChangeStatusToBackordered = async () => {
        const data: OrderUpdate = {
            id: id,
            status: "Backordered",
            UpdatedById: user.profile.id,
        };
        const response = await order_patch(data);
        if (response.status === "success") {
            snack("success", "Updated order!");
            update();
            close();
        } else {
            snack("error", "Error updating order. " + response.message);
        }
    };

    return (
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={close}>
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
                Set As:
            </Typography>
            {currentStatus === "Not Ordered" && (
                <Fragment>
                    <MenuItem onClick={handleChangeStatusToOrdered}>Item Ordered</MenuItem>
                    <MenuItem onClick={handleChangeStatusToBackordered}>Backordered</MenuItem>
                    <MenuItem onClick={handleChangeStatusToCancelled}>Cancelled</MenuItem>
                </Fragment>
            )}
            {(currentStatus === "Ordered" || currentStatus === "Backordered") && (
                <Fragment>
                    <MenuItem onClick={handleChangeStatusToReceived}>Received</MenuItem>
                    <MenuItem onClick={handleChangeStatusToBackordered}>Backordered</MenuItem>
                    <MenuItem onClick={handleChangeStatusToCancelled}>Cancelled</MenuItem>
                </Fragment>
            )}
            {currentStatus === "Received" && (
                <Fragment>
                    <MenuItem onClick={handleChangeStatusToPickedUp}>Picked Up</MenuItem>
                    <MenuItem onClick={handleChangeStatusToCancelled}>Cancelled</MenuItem>
                </Fragment>
            )}
        </Menu>
    );
};

export default QuickMenu;
