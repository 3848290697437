import { User } from "interface/user.interface";
import React, { ReactNode, createContext, useContext, useMemo, useState } from "react";

interface UserData {
    profile: User;
    setProfile: React.Dispatch<React.SetStateAction<User>>;
}

export const userProfileDefaultValue: User = {
    id: "",
    name: "",
    email: "",
};

const userDefaultValue: UserData = {
    profile: userProfileDefaultValue,
    setProfile: () => null,
};

const useProvideUser = () => {
    const [profile, setProfile] = useState<User>(userDefaultValue.profile);
    return useMemo(() => ({ profile, setProfile }), [profile, setProfile]);
};

const UserContext = createContext<UserData>(userDefaultValue);

export const useUser = () => {
    return useContext(UserContext);
};

export const ProvideUser = (props: { children: ReactNode }) => {
    const { children } = props;
    const user = useProvideUser();
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
