import { API_URL } from "config/api";
import { TodayStatus } from "interface/current.interface";

export const CURRENT_API = `${API_URL}/current`;

export const current_today_hours = async (): Promise<{ status: string; data?: TodayStatus; message?: string }> => {
    const response = await fetch(`${CURRENT_API}/today-hours`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};
