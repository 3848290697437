import { Button, Card, CardActions, CardContent, Menu, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { UpdatedHours } from "interface/updatedHours.interface";
import { FC, useState } from "react";

interface UpdatedHoursCardProps {
    index: number;
    updatedHours: UpdatedHours;
    editUpdatedHours: (index: number) => void;
    deleteUpdatedHours: (index: number) => void;
}

const UpdatedHoursCard: FC<UpdatedHoursCardProps> = (props: UpdatedHoursCardProps) => {
    const { index, updatedHours, editUpdatedHours, deleteUpdatedHours } = props;
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const handleEdit = () => {
        setAnchorEl(null);
        editUpdatedHours(index);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        deleteUpdatedHours(index);
    };

    return (
        <Card sx={{ width: 200, m: theme.spacing(2) }}>
            <CardContent>
                <Typography variant="h6" sx={{ mb: theme.spacing(1) }}>
                    {updatedHours.date}
                </Typography>
                {updatedHours.closed ? (
                    <Typography color="error" variant="body1" sx={{ ml: theme.spacing(2) }}>
                        Closed
                    </Typography>
                ) : (
                    <Typography color="primary" variant="body1" sx={{ ml: theme.spacing(2) }}>
                        {updatedHours.open} &mdash; {updatedHours.close}
                    </Typography>
                )}
            </CardContent>
            <CardActions>
                <Button
                    id={`options-${updatedHours.date}`}
                    aria-controls={openMenu ? "options-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                    Options
                </Button>
                <Menu id="options-menu" anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <MenuItem onClick={handleDelete} sx={{ color: "red" }}>
                        Delete
                    </MenuItem>
                </Menu>
            </CardActions>
        </Card>
    );
};

export default UpdatedHoursCard;
