import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { page_post } from "api/page.api";
import { PageCreation } from "interface/page.interface";
import { FC, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";

interface AddPageProps {
    open: boolean;
    handleClose: () => void;
    updatePages: () => void;
}

const AddPage: FC<AddPageProps> = (props: AddPageProps) => {
    const [pageName, setPageName] = useState<string>("");
    const [order, setOrder] = useState<number>(1);

    const { open, handleClose, updatePages } = props;
    const snack = useSnack();

    const handleCancel = () => {
        setPageName("");
        setOrder(0);
        handleClose();
    };

    const handleSave = async () => {
        if (!pageName) {
            snack("error", "Must enter a page name.");
            return;
        }
        const path = pageName.toLowerCase().replace(/ /g, "-");
        const data: PageCreation = {
            name: pageName,
            displayInNav: false,
            path: path,
        };
        if (order) data["order"] = order;
        const response = await page_post(data);
        if (response.status === "success") {
            handleClose();
            updatePages();
        } else {
            snack("error", response.status + ": " + response.message);
        }
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Add New Page</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Page Name"
                    value={pageName}
                    onChange={(e) => setPageName(e.target.value)}
                    variant="standard"
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Page Order"
                    value={order}
                    type="number"
                    onChange={(e) => setOrder(Number(e.target.value))}
                    variant="standard"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} startIcon={<CancelIcon />}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSave} startIcon={<SaveIcon />}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddPage;
