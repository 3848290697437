import CloseIcon from "@mui/icons-material/Close";
import DirectionsIcon from "@mui/icons-material/Directions";
import InfoIcon from "@mui/icons-material/Info";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Divider, Fab, IconButton, Popover, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { current_today_hours } from "api/current.api";
import { TodayStatus } from "interface/current.interface";
import { FC, useEffect, useState } from "react";
import useSnack from "./ProvideSnack";

const CornerFab: FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const snack = useSnack();
    const [todayStatus, setTodayStatus] = useState<TodayStatus | null>(null);

    const handleGetTodayStatus = async () => {
        const response = await current_today_hours();
        if (response.status === "success") {
            setTodayStatus(response.data!);
        } else {
            snack("error", "Error getting today status. " + response.message);
        }
    };

    useEffect(() => {
        handleGetTodayStatus();
    }, []);

    return (
        todayStatus && (
            <>
                <Fab
                    sx={{ position: "fixed", right: 24, bottom: 24, fontSize: 40 }}
                    color="secondary"
                    aria-label="info"
                    onClick={(e) => setAnchorEl(e.currentTarget as HTMLButtonElement)}
                >
                    {open ? <CloseIcon /> : <InfoIcon />}
                </Fab>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    <Box sx={{ p: theme.spacing(2) }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                mb: theme.spacing(2),
                            }}
                        >
                            <Typography variant="h6">We are {todayStatus.status}</Typography>
                            {todayStatus.hours ? (
                                <Typography variant="body1" sx={{ mt: theme.spacing(1) }}>
                                    Today: {todayStatus.hours}
                                </Typography>
                            ) : null}
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                m: theme.spacing(2),
                            }}
                        >
                            <IconButton
                                sx={{ fontSize: 40, color: theme.palette.primary.main }}
                                LinkComponent={"a"}
                                href="tel: +1-720-379-6492"
                            >
                                <PhoneIcon />
                            </IconButton>
                            <Typography variant="body1">720-379-6492</Typography>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                m: theme.spacing(2),
                            }}
                        >
                            <IconButton
                                sx={{ fontSize: 40, color: theme.palette.primary.main }}
                                LinkComponent={"a"}
                                href="https://www.google.com/maps/place/Tenback+Bowling+Outfitters/@39.7016718,-104.9125861,17z/data=!3m1!4b1!4m6!3m5!1s0x876c7db6cbc2c649:0x465eb71dfbc70c3e!8m2!3d39.7016677!4d-104.9100112!16s%2Fg%2F1pp2w_gvg?entry=ttu"
                                target="_blank"
                            >
                                <DirectionsIcon />
                            </IconButton>
                            <Typography variant="body1">
                                6767 Leetsdale Dr
                                <br />
                                Denver, CO 80224
                            </Typography>
                        </Box>
                    </Box>
                </Popover>
            </>
        )
    );
};

export default CornerFab;
