import { PageList } from "interface/page.interface";
import { createBrowserRouter } from "react-router-dom";
import AboutUs from "routes/AboutUs/AboutUs";
import FooterLinks from "routes/Admin/footer-links/FooterLinks";
import HoursAdmin from "routes/Admin/hours/HoursAdmin";
import Orders from "routes/Admin/orders/Orders";
import EditPage from "routes/Admin/pages/EditPage";
import Pages from "routes/Admin/pages/Pages";
import AdminPatchNotes from "routes/Admin/patch-notes/AdminPatchNotes";
import Users from "routes/Admin/users/Users";
import Contact from "routes/Contact/Contact";
import Equipment from "routes/Equipment/Equipment";
import GenericPage from "routes/common/components/GenericPage";
import Admin from "./routes/Admin/Admin";
import ErrorPage from "./routes/ErrorPage/ErrorPage";
import Home from "./routes/Home/Home";
import Root from "./routes/Root";

export const handleDisplayPages = (pageList: PageList[]) => {
    const pages = pageList
        .filter((page) => page.displayInNav === true)
        .sort((a, b) => (a.order as number) - (b.order as number));

    return pages;
};

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/home",
                element: <Home />,
            },
            {
                path: "/equipment",
                element: <Equipment />,
            },
            {
                path: "/:path",
                element: <GenericPage />,
            },
            {
                path: "/about",
                element: <AboutUs />,
            },
            {
                path: "/contact-us",
                element: <Contact />,
            },
            {
                path: "/admin",
                element: <Admin />,
                children: [
                    {
                        path: "/admin/orders",
                        element: <Orders />,
                    },
                    {
                        path: "/admin/users",
                        element: <Users />,
                    },
                    {
                        path: "/admin/pages",
                        element: <Pages />,
                        children: [
                            {
                                path: "/admin/pages/:path",
                                element: <EditPage />,
                            },
                        ],
                    },
                    {
                        path: "/admin/hours",
                        element: <HoursAdmin />,
                    },
                    {
                        path: "/admin/footer-links",
                        element: <FooterLinks />,
                    },
                    {
                        path: "/admin/patch-notes",
                        element: <AdminPatchNotes />,
                    },
                ],
            },
        ],
    },
]);
