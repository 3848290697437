import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { updatedHours_patch, updatedHours_post } from "api/updatedHours.api";
import { UpdatedHours } from "interface/updatedHours.interface";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";

interface EditUpdatedHoursProps {
    mode: "add" | "edit";
    open: boolean;
    updatedHours?: UpdatedHours;
    updateUpdatedHours: () => void;
    handleClose: () => void;
}

const EditUpdatedHours: FC<EditUpdatedHoursProps> = (props: EditUpdatedHoursProps) => {
    const { mode, open, updatedHours, updateUpdatedHours, handleClose } = props;
    const theme = useTheme();
    const snack = useSnack();

    const [date, setDate] = useState<string>(updatedHours?.date || moment().format("YYYY-MM-DD"));
    const [closed, setClosed] = useState<boolean>(updatedHours?.closed || false);
    const [openTime, setOpenTime] = useState<string>(updatedHours?.open || "00:00");
    const [closeTime, setCloseTime] = useState<string>(updatedHours?.open || "00:00");

    const handleCancel = () => {
        setDate(moment().format("YYYY-MM-DD"));
        setClosed(false);
        setOpenTime("00:00");
        setCloseTime("00:00");
        handleClose();
    };

    const handleLoad = () => {
        if (updatedHours) {
            setDate(updatedHours.date);
            setClosed(updatedHours.closed);
            setCloseTime(updatedHours.close);
            setOpenTime(updatedHours.open);
        }
    };

    const handleSubmit = async () => {
        const data: UpdatedHours = {
            date: date,
            closed: closed,
            open: openTime,
            close: closeTime,
        } as UpdatedHours;
        if (mode === "edit" && updatedHours) {
            data["id"] = updatedHours!.id;
            const response1 = await updatedHours_patch(data);
            if (response1.status === "success" && response1.data![0] === 1) {
                snack("success", "Hours successfully saved");
                updateUpdatedHours();
                handleCancel();
            } else {
                snack("error", "Error saving hours. " + response1.message);
            }
        } else {
            const response2 = await updatedHours_post(data);
            if (response2.status === "success" && response2.data!.id) {
                snack("success", "Updated Hours successfully saved");
                updateUpdatedHours();
                handleCancel();
            } else {
                snack("error", "Error saving hours. " + response2.message);
            }
        }
    };

    useEffect(() => {
        handleLoad();
    }, [updatedHours]);

    return (
        <Dialog open={open}>
            <IconButton onClick={handleCancel} size="large" sx={{ position: "absolute", right: 0, top: 0 }}>
                <CloseIcon color="error" sx={{ fontSize: 24 }} />
            </IconButton>
            <DialogTitle>{mode === "add" ? "Add" : "Edit"} Updated Hours</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1">
                    {mode === "add"
                        ? "Add a new one time hours change for the store."
                        : "Update the updated hours for the store."}
                </Typography>
                <TextField
                    label="Date"
                    value={date}
                    type="date"
                    onChange={(e) => setDate(e.target.value)}
                    required
                    sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }}
                />
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <FormControlLabel
                        control={<Switch checked={closed} onChange={(e) => setClosed(e.target.checked)} />}
                        label={closed ? "Closed" : "Open"}
                        sx={{ pr: theme.spacing(0.5) }}
                    />
                    <TextField
                        label="Open"
                        type="time"
                        value={openTime}
                        onChange={(e) => setOpenTime(e.target.value)}
                    />
                    <TextField
                        label="Close"
                        type="time"
                        value={closeTime}
                        onChange={(e) => setCloseTime(e.target.value)}
                    />
                </Box>
                <Divider sx={{ m: theme.spacing(1) }} />
                <Box sx={{ mt: theme.spacing(2), display: "flex", justifyContent: "space-around" }}>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Save
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default EditUpdatedHours;
