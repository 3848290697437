import { Box, CircularProgress } from "@mui/material";
import React, { FC } from "react";

const Loading: FC = () => {
    return (
        <Box sx={{ position: "fixed", top: "25%", left: "50%", transform: "translate(-50%,-50%)" }}>
            <CircularProgress size={100} color="error" />
        </Box>
    );
};

export default Loading;
