import { Box, Button, CardMedia, Paper, Typography, useTheme } from "@mui/material";
import { Card as CardI } from "interface/card.interface";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface HomeCardProps {
    card: CardI;
}

const HomeCard: FC<HomeCardProps> = (props: HomeCardProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { card } = props;

    return (
        <Paper
            sx={{
                m: theme.spacing(1),
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                p: theme.spacing(1),
            }}
        >
            {card.image ? (
                <CardMedia
                    src={card.image}
                    // title={card.imageTitle}
                    component="img"
                    sx={{ height: 175, width: 175, borderRadius: 2, pr: { xs: 0, sm: theme.spacing(2) } }}
                />
            ) : null}
            <Box
                sx={{
                    textAlign: "center",
                    height: 175,
                    width: { xs: "95%", sm: 200 },
                    p: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">{card.title}</Typography>
                <Typography variant="body1">{card.subtitle}</Typography>
                {card.link ? <Button onClick={() => navigate(card.link!)}>{card.linkText}</Button> : null}
            </Box>
        </Paper>
    );
};

export default HomeCard;
