import { API_URL } from "config/api";
import { FooterLink, FooterLinkCreate, FooterLinkMove, FooterLinkUpdate } from "interface/footerLink.interface";

export const FOOTERLINK_URL = `${API_URL}/footer-link`;

export const footerLink_getAll = async (): Promise<{ status: string; data: [FooterLink]; message: string }> => {
    const response = await fetch(`${FOOTERLINK_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const footerLink_post = async (
    newDocument: FooterLinkCreate,
): Promise<{ status: string; data?: FooterLink; message: string }> => {
    const response = await fetch(`${FOOTERLINK_URL}`, {
        method: "POST",
        body: JSON.stringify(newDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const footerLink_patch = async (
    updatedDocument: FooterLinkUpdate,
): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${FOOTERLINK_URL}`, {
        method: "PATCH",
        body: JSON.stringify(updatedDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const footerLink_move = async (
    update: FooterLinkMove,
): Promise<{ status: string; data: number[]; message: string }> => {
    const response = await fetch(`${FOOTERLINK_URL}/move`, {
        method: "PATCH",
        body: JSON.stringify(update),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const footerLink_delete = async (id: string): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${FOOTERLINK_URL}`, {
        method: "DELETE",
        body: JSON.stringify({ id: id }),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};
