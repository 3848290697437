import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { user_getAll } from "api/user.api";
import { User } from "interface/user.interface";
import { FC, useEffect, useState } from "react";
import AddButton from "routes/common/components/AddButton";
import useSnack from "routes/common/components/ProvideSnack";
import SearchBar from "routes/common/components/SearchBar";
import EditUser from "./EditUser";

const Users: FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [mode, setMode] = useState<"add" | "edit">("add");
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [, setSearchText] = useState<string>("");
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const snack = useSnack();
    const theme = useTheme();

    const handleGetUsers = async () => {
        const response = await user_getAll();
        if (response.status === "success") {
            setUsers(response.data);
            setFilteredUsers(response.data);
        } else {
            snack("error", "Error retrieving user data: " + response.message);
        }
    };

    const handleSelectUser = (index: number) => {
        setSelectedUser(users[index]);
        setMode("edit");
        setOpenDialog(true);
    };

    const handleSearch = (text?: string) => {
        if (text) {
            setSearchText(text);
            setFilteredUsers(users.filter((user) => user.name.includes(text)));
        } else {
            setFilteredUsers(users);
        }
    };

    const handleUpdateUsers = async () => {
        await handleGetUsers();
        // handleSearch(searchText);
    };

    useEffect(() => {
        handleGetUsers();
    }, []);

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: theme.spacing(2) }}>
                <Typography variant="h4">User Management</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <AddButton
                        onClick={() => {
                            setMode("add");
                            setOpenDialog(true);
                        }}
                    />
                    <Box sx={{ width: 8 }} />
                    <SearchBar searchFn={handleSearch} />
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Birthday</TableCell>
                            <TableCell>Admin</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user, index) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.phone}</TableCell>
                                <TableCell>{user.birthday}</TableCell>
                                <TableCell>{user.admin ? "Admin" : ""}</TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={() => handleSelectUser(index)}>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <EditUser
                open={openDialog}
                user={selectedUser}
                mode={mode}
                handleClose={() => setOpenDialog(false)}
                updateUsers={handleUpdateUsers}
            />
        </Box>
    );
};

export default Users;
