import { API_URL } from "config/api";
import { Auth0User, User, UserCreate, UserUpdate } from "interface/user.interface";

export const USER_URL = `${API_URL}/user`;

export const user_getAll = async (): Promise<{ status: string; data: User[]; message: string }> => {
    const response = await fetch(`${USER_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const user_getById = async (id: string): Promise<{ status: string; data: User; message: string }> => {
    const response = await fetch(`${USER_URL}/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const user_checkEmail = async (email: string): Promise<{ status: string; data: any; message: string }> => {
    const response = await fetch(`${USER_URL}/check?email=${email}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const user_login = async (auth0User: Auth0User): Promise<{ status: string; data?: User; message?: string }> => {
    const response = await fetch(`${USER_URL}/login`, {
        method: "POST",
        body: JSON.stringify(auth0User),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const user_post = async (newDocument: UserCreate): Promise<{ status: string; data: User; message: string }> => {
    const response = await fetch(`${USER_URL}`, {
        method: "POST",
        body: JSON.stringify(newDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const user_patch = async (
    updatedDocument: UserUpdate,
): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${USER_URL}`, {
        method: "PATCH",
        body: JSON.stringify(updatedDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const user_delete = async (id: string): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${USER_URL}`, {
        method: "DELETE",
        body: JSON.stringify({ id: id }),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};
