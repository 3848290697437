import { API_URL } from "config/api";
import { Order, OrderCreate, OrderPopulated, OrderUpdate } from "interface/order.interface";

export const ORDER_URL = `${API_URL}/order`;

export const order_getAll = async (): Promise<{ status: string; data: OrderPopulated[]; message: string }> => {
    const response = await fetch(`${ORDER_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const order_getById = async (id: string): Promise<{ status: string; data: OrderPopulated; message: string }> => {
    const response = await fetch(`${ORDER_URL}/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const order_post = async (
    newDocument: OrderCreate,
): Promise<{ status: string; data: Order; message: string }> => {
    const response = await fetch(`${ORDER_URL}`, {
        method: "POST",
        body: JSON.stringify(newDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const order_patch = async (
    updatedDocument: OrderUpdate,
): Promise<{ status: string; data: number[]; message: string }> => {
    const response = await fetch(`${ORDER_URL}`, {
        method: "PATCH",
        body: JSON.stringify(updatedDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const order_delete = async (id: string): Promise<{ status: string; data: number[]; message: string }> => {
    const response = await fetch(`${ORDER_URL}`, {
        method: "DELETE",
        body: JSON.stringify({ id: id }),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};
