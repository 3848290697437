import { Box, Button, CardMedia, Paper, Typography, useTheme } from "@mui/material";
import { Card as CardI } from "interface/card.interface";
import { FC } from "react";

interface ContactCardProps {
    card: CardI;
}

const ContactCard: FC<ContactCardProps> = (props: ContactCardProps) => {
    const theme = useTheme();
    const { card } = props;

    return (
        <Paper
            sx={{
                m: theme.spacing(1),
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                p: theme.spacing(1),
            }}
        >
            {card.image ? (
                <CardMedia
                    src={card.image}
                    // title={card.imageTitle}
                    component="img"
                    sx={{ height: 175, width: 175, borderRadius: 2, pr: theme.spacing(2) }}
                />
            ) : null}
            <Box
                sx={{
                    textAlign: "center",
                    height: 175,
                    width: 200,
                    p: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">{card.title}</Typography>
                <Typography variant="body1" sx={{ maxWidth: "100%", wordBreak: "break-word" }}>
                    {card.subtitle}
                </Typography>
                {card.link && (
                    <Box sx={{ justifyContent: "center" }}>
                        <Button LinkComponent={"a"} size="small" color="primary" href={card.link} target="_blank">
                            {card.linkText}
                        </Button>
                    </Box>
                )}
            </Box>
        </Paper>
    );
};

export default ContactCard;
