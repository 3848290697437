import { API_URL } from "config/api";
import { UpdatedHours, UpdatedHoursUpdate } from "interface/updatedHours.interface";

export const UPDATEDHOURS_URL = `${API_URL}/updated-hours`;

export const updatedHours_getAll = async (): Promise<{ status: string; data?: UpdatedHours[]; message: string }> => {
    const response = await fetch(`${UPDATEDHOURS_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const updatedHours_getToday = async (): Promise<{ status: string; data?: UpdatedHours; message: string }> => {
    const response = await fetch(`${UPDATEDHOURS_URL}/today`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const updatedHours_getWeek = async (): Promise<{ status: string; data?: UpdatedHours[]; message: string }> => {
    const response = await fetch(`${UPDATEDHOURS_URL}/week`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const updatedHours_getById = async (
    id: string,
): Promise<{ status: string; data?: UpdatedHours; message: string }> => {
    const response = await fetch(`${UPDATEDHOURS_URL}/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const updatedHours_post = async (
    newDocument: UpdatedHours,
): Promise<{ status: string; data?: UpdatedHours; message: string }> => {
    const response = await fetch(`${UPDATEDHOURS_URL}`, {
        method: "POST",
        body: JSON.stringify(newDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const updatedHours_patch = async (
    updatedDocument: UpdatedHoursUpdate,
): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${UPDATEDHOURS_URL}`, {
        method: "PATCH",
        body: JSON.stringify(updatedDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const updatedHours_delete = async (
    id: string,
): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${UPDATEDHOURS_URL}`, {
        method: "DELETE",
        body: JSON.stringify({ id: id }),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};
