import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { order_patch } from "api/order.api";
import dayjs from "dayjs";
import { useUser } from "hooks/User";
import { OrderCreate, OrderPopulated, OrderUpdate } from "interface/order.interface";
import { User } from "interface/user.interface";
import { ChangeEvent, FC, useMemo, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";

interface Props {
    order: OrderPopulated;
    open: boolean;
    close: () => void;
    update: () => void;
}

const EditOrder: FC<Props> = (props: Props) => {
    const { order, open, close, update } = props;
    const theme = useTheme();
    const snack = useSnack();
    const user = useUser();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    const [status, setStatus] = useState<string>("");
    const [selectedUser, setSelectedUser] = useState<User | null>(order.User);
    const [itemCustom, setItemCustom] = useState<string>(order.itemCustom);
    const [size, setSize] = useState<string>(order.size);
    const [quantity, setQuantity] = useState<number>(order.quantity);
    const [otherDesc, setOtherDesc] = useState<string>(order.otherDesc);
    const [paid, setPaid] = useState<boolean>(order.paid);
    const [supplier, setSupplier] = useState<string>(order.supplier);
    const [orderedDate, setOrderedDate] = useState<dayjs.Dayjs | null>(
        order.orderedDate ? dayjs(order.orderedDate) : null,
    );
    const [receivedDate, setReceivedDate] = useState<dayjs.Dayjs | null>(
        order.receivedDate ? dayjs(order.receivedDate) : null,
    );
    const [pickedUpDate, setPickedUpDate] = useState<dayjs.Dayjs | null>(
        order.pickedUpDate ? dayjs(order.pickedUpDate) : null,
    );
    const [notes, setNotes] = useState<string>(order.notes);

    const handleClose = () => {
        setStatus("");
        setSelectedUser(null);
        setItemCustom("");
        setSize("");
        setQuantity(1);
        setOtherDesc("");
        setPaid(false);
        setSupplier("");
        setNotes("");
        close();
    };

    const changes = useMemo(() => {
        const current: OrderCreate = {
            UserId: selectedUser?.id ?? "",
            itemCustom: itemCustom,
            size: size,
            quantity: quantity,
            otherDesc: otherDesc,
            paid: paid,
            supplier: supplier,
            notes: notes,
            status: status,
            orderedDate: orderedDate?.toString(),
            receivedDate: receivedDate?.toString(),
            pickedUpDate: pickedUpDate?.toString(),
            EnteredById: order.EnteredById,
        };
        for (const key in current) {
            if (current[key] !== order[key]) return true;
        }
        return false;
    }, [selectedUser, itemCustom, size, quantity, otherDesc, paid, supplier, notes]);

    const allowSave = useMemo(() => {
        if (selectedUser === null || itemCustom === "" || quantity < 1) return false;
        else return true;
    }, [selectedUser, itemCustom, quantity]);

    const calcStatus = () => {
        if (pickedUpDate) return "Complete";
        if (receivedDate) return "Received";
        if (orderedDate) return "Ordered";
        else return "Not Ordered";
    };

    useMemo(() => {
        setStatus(calcStatus());
    }, [pickedUpDate, orderedDate, receivedDate]);

    const handleSave = async () => {
        const info: OrderUpdate = {
            id: order.id,
            UserId: selectedUser?.id as string,
            itemCustom: itemCustom,
            size: size,
            quantity: quantity,
            otherDesc: otherDesc,
            status: status,
            paid: paid,
            supplier: supplier,
            notes: notes,
            archived: status === "Complete",
            orderedDate: orderedDate?.toString(),
            receivedDate: receivedDate?.toString(),
            pickedUpDate: pickedUpDate?.toString(),
            UpdatedById: user.profile.id,
        };
        const response = await order_patch(info);
        if (response.status === "success") {
            snack("success", "New Order Saved");
            handleClose();
            update();
        } else {
            snack("error", "Error saving order. " + response.message);
        }
    };

    return (
        <Dialog open={open} fullScreen={mobile}>
            <DialogTitle>Add New Order</DialogTitle>
            <DialogContent sx={{ width: mobile ? "auto" : 500, overflowX: "hidden" }}>
                <TextField disabled sx={{ mt: 1.5 }} label="Order Status" fullWidth value={status} />
                <TextField disabled sx={{ mt: 1.5 }} label="Customer" fullWidth value={order.User.name} />
                <TextField
                    sx={{ mt: 1.5 }}
                    value={itemCustom}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setItemCustom(e.target.value)}
                    label="Item Details"
                    fullWidth
                />
                <TextField
                    sx={{ mt: 1.5 }}
                    value={size}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSize(e.target.value)}
                    label="Size"
                    fullWidth
                />
                <TextField
                    type="number"
                    sx={{ mt: 1.5 }}
                    value={quantity}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
                    label="Quantity"
                    fullWidth
                />
                <TextField
                    multiline
                    sx={{ mt: 1.5 }}
                    minRows={3}
                    value={otherDesc}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setOtherDesc(e.target.value)}
                    label="Other Details"
                    fullWidth
                />
                <FormControlLabel
                    sx={{ mt: 1.5, ml: 1.5 }}
                    control={<Checkbox checked={paid} onChange={(e) => setPaid(e.target.checked)} />}
                    label="Paid"
                />
                <TextField
                    sx={{ mt: 1.5 }}
                    value={supplier}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSupplier(e.target.value)}
                    label="Supplier"
                    fullWidth
                />
                <TextField
                    multiline
                    sx={{ mt: 1.5 }}
                    minRows={3}
                    value={notes}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setNotes(e.target.value)}
                    label="Notes"
                    fullWidth
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        sx={{ width: "100%", mt: 1.5 }}
                        label="Ordered Date"
                        value={orderedDate}
                        onChange={(value) => setOrderedDate(value)}
                    />
                    <DatePicker
                        sx={{ width: "100%", mt: 1.5 }}
                        label="Received Date"
                        value={receivedDate}
                        onChange={(value) => setReceivedDate(value)}
                        disabled={Boolean(!orderedDate)}
                    />
                    <DatePicker
                        sx={{ width: "100%", mt: 1.5 }}
                        label="Picked Up Date"
                        value={pickedUpDate}
                        onChange={(value) => setPickedUpDate(value)}
                        disabled={Boolean(!receivedDate)}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{changes ? "Cancel" : "Close"}</Button>
                <Button disabled={!changes || !allowSave} onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditOrder;
