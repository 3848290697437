import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EditNoteIcon from "@mui/icons-material/EditNote";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Collapse, Divider, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { OrderPopulated } from "interface/order.interface";
import { MouseEvent, useState } from "react";

interface Props {
    order: OrderPopulated;
    edit: (order: OrderPopulated) => void;
    deleteItem: (id: string) => void;
    openMenu: (e: MouseEvent<HTMLButtonElement>, status: string, id: string) => void;
}

const SimpleRow = (props: Props) => {
    const { order, edit, deleteItem, openMenu } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Paper sx={{ p: 1, mb: 1 }}>
            <Box sx={{ display: "flex" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Box sx={{ mr: theme.spacing(2), display: mobile ? "none" : "inherit" }}>
                        <IconButton onClick={() => edit(order)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={(e) => openMenu(e, order.status, order.id)}>
                            <EditNoteIcon />
                        </IconButton>
                        <IconButton onClick={() => deleteItem(order.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    <Typography
                        sx={{
                            width: mobile ? "100%" : "35%",
                            fontWeight: "bold",
                            color: order.paid ? "inherit" : "red",
                        }}
                    >
                        {order.status} - {order.paid ? "Paid" : "Not Paid"}
                    </Typography>
                    <Typography sx={{ width: mobile ? "100%" : "25%" }}>{order.User.name}</Typography>
                    <Typography sx={{ width: mobile ? "100%" : "auto", flexGrow: 1 }}>{order.itemCustom}</Typography>
                </Box>
                <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ display: mobile ? "block" : "none" }}>
                    <IconButton onClick={() => edit(order)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={(e) => openMenu(e, order.status, order.id)}>
                        <EditNoteIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteItem(order.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        m: mobile ? 0 : 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                    }}
                >
                    <Box sx={{ width: "100%", maxWidth: 300, m: 1 }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Customer Details
                        </Typography>
                        <Divider />
                        <Typography variant="body2">Name: {order.User.name}</Typography>
                        <Typography variant="body2">Email: {order.User.email}</Typography>
                        <Typography variant="body2">Phone: {order.User.phone}</Typography>
                    </Box>
                    <Box sx={{ width: "100%", maxWidth: 300, m: 1 }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Order Details
                        </Typography>
                        <Divider />
                        <Typography variant="body2">Item: {order.itemCustom}</Typography>
                        <Typography variant="body2">Size: {order.size}</Typography>
                        <Typography variant="body2">Quantity: {order.quantity}</Typography>
                        <Typography variant="body2">Supplier: {order.supplier}</Typography>
                        <Typography variant="body2">Other Details: {order.otherDesc}</Typography>
                    </Box>
                    <Box sx={{ width: "100%", maxWidth: 300, m: 1 }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Dates
                        </Typography>
                        <Divider />
                        <Typography variant="body2">
                            Created At: {order.createdAt ? dayjs(order.createdAt).format("MM-DD-YYYY") : "N/A"}
                        </Typography>
                        <Typography variant="body2">
                            Ordered: {order.orderedDate ? dayjs(order.orderedDate).format("MM-DD-YYYY") : "N/A"}
                        </Typography>
                        <Typography variant="body2">
                            Received: {order.receivedDate ? dayjs(order.receivedDate).format("MM-DD-YYYY") : "N/A"}
                        </Typography>
                        <Typography variant="body2">
                            Picked Up: {order.pickedUpDate ? dayjs(order.pickedUpDate).format("MM-DD-YYYY") : "N/A"}
                        </Typography>
                    </Box>
                    <Box sx={{ width: "100%", maxWidth: 300, m: 1 }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Notes
                        </Typography>
                        <Divider />
                        <Typography variant="body2">{order.notes ? order.notes : "None"}</Typography>
                        <Typography variant="body2">Entered By: {order.EnteredBy?.name ?? "N/A"}</Typography>
                        <Typography variant="body2">Last Updated By: {order.UpdatedBy?.name ?? "N/A"}</Typography>
                    </Box>
                </Box>
            </Collapse>
        </Paper>
    );
};

export default SimpleRow;
