import AddIcon from "@mui/icons-material/Add";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { order_post } from "api/order.api";
import { user_getAll } from "api/user.api";
import { useUser } from "hooks/User";
import { OrderCreate } from "interface/order.interface";
import { User } from "interface/user.interface";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";
import EditUser from "../../users/EditUser";

interface Props {
    open: boolean;
    close: () => void;
    update: () => void;
}

const defaultValues: OrderCreate = {
    UserId: "",
    itemCustom: "",
    size: "",
    quantity: 1,
    otherDesc: "",
    paid: false,
    supplier: "",
    notes: "",
    EnteredById: "",
};

const NewOrder: FC<Props> = (props: Props) => {
    const { open, close, update } = props;
    const theme = useTheme();
    const snack = useSnack();
    const user = useUser();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    const [openUserDialog, setOpenUserDialog] = useState<boolean>(false);

    const [userList, setUserList] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [selectedUserName, setSelectedUserName] = useState<string>("");
    const [itemCustom, setItemCustom] = useState<string>(defaultValues.itemCustom);
    const [size, setSize] = useState<string>(defaultValues.size as string);
    const [quantity, setQuantity] = useState<number>(defaultValues.quantity);
    const [otherDesc, setOtherDesc] = useState<string>(defaultValues.otherDesc as string);
    const [paid, setPaid] = useState<boolean>(defaultValues.paid as boolean);
    const [supplier, setSupplier] = useState<string>(defaultValues.supplier as string);
    const [notes, setNotes] = useState<string>(defaultValues.notes as string);

    const handleClose = () => {
        setSelectedUser(defaultValues.selectedUser);
        setSelectedUserName("");
        setItemCustom(defaultValues.itemCustom);
        setSize(defaultValues.size as string);
        setQuantity(defaultValues.quantity);
        setOtherDesc(defaultValues.otherDesc as string);
        setPaid(defaultValues.paid as boolean);
        setSupplier(defaultValues.supplier as string);
        setNotes(defaultValues.notes as string);
        close();
    };

    const changes = useMemo(() => {
        const current: OrderCreate = {
            UserId: selectedUser?.id ?? "",
            itemCustom: itemCustom,
            size: size,
            quantity: quantity,
            otherDesc: otherDesc,
            paid: paid,
            supplier: supplier,
            notes: notes,
            EnteredById: user.profile.id,
        };
        for (const key in current) {
            if (current[key] !== defaultValues[key]) return true;
        }
        return false;
    }, [selectedUser, itemCustom, size, quantity, otherDesc, paid, supplier, notes]);

    const allowSave = useMemo(() => {
        if (selectedUser === null || itemCustom === "" || quantity < 1) return false;
        else return true;
    }, [selectedUser, itemCustom, quantity]);

    const getUserList = async () => {
        const response = await user_getAll();
        if (response.status === "success") {
            setUserList(response.data);
        } else {
            snack("error", "Unable to retrieve user list.");
        }
    };

    const handleSave = async () => {
        const info: OrderCreate = {
            UserId: selectedUser?.id as string,
            itemCustom: itemCustom,
            size: size,
            quantity: quantity,
            otherDesc: otherDesc,
            status: "Not Ordered",
            paid: paid,
            supplier: supplier,
            notes: notes,
            EnteredById: user.profile.id,
        };
        const response = await order_post(info);
        if (response.status === "success") {
            snack("success", "New Order Saved");
            handleClose();
            update();
        } else {
            snack("error", "Error saving order. " + response.message);
        }
    };

    useEffect(() => {
        getUserList();
    }, []);

    return (
        <Dialog open={open} fullScreen={mobile}>
            <DialogTitle>Add New Order</DialogTitle>
            <DialogContent sx={{ width: mobile ? "auto" : 500, overflowX: "hidden" }}>
                <Box sx={{ display: "flex" }}>
                    <Autocomplete
                        sx={{ mt: 1 }}
                        options={userList}
                        fullWidth
                        value={selectedUser}
                        onChange={(event, newValue) => setSelectedUser(newValue)}
                        inputValue={selectedUserName}
                        onInputChange={(event, newInputValue) => setSelectedUserName(newInputValue)}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Select Customer" />}
                    />
                    <IconButton onClick={() => setOpenUserDialog(true)}>
                        <AddIcon />
                    </IconButton>
                </Box>
                <TextField
                    sx={{ mt: 1.5 }}
                    value={itemCustom}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setItemCustom(e.target.value)}
                    label="Item Details"
                    fullWidth
                />
                <TextField
                    sx={{ mt: 1.5 }}
                    value={size}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSize(e.target.value)}
                    label="Size"
                    fullWidth
                />
                <TextField
                    type="number"
                    sx={{ mt: 1.5 }}
                    value={quantity}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
                    label="Quantity"
                    fullWidth
                />
                <TextField
                    multiline
                    sx={{ mt: 1.5 }}
                    minRows={3}
                    value={otherDesc}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setOtherDesc(e.target.value)}
                    label="Other Details"
                    fullWidth
                />
                <FormControlLabel
                    sx={{ mt: 1.5, ml: 1.5 }}
                    control={<Checkbox checked={paid} onChange={(e) => setPaid(e.target.checked)} />}
                    label="Paid"
                />
                <TextField
                    sx={{ mt: 1.5 }}
                    value={supplier}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSupplier(e.target.value)}
                    label="Supplier"
                    fullWidth
                />
                <TextField
                    multiline
                    sx={{ mt: 1.5 }}
                    minRows={3}
                    value={notes}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setNotes(e.target.value)}
                    label="Notes"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{changes ? "Cancel" : "Close"}</Button>
                <Button disabled={!changes || !allowSave} onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>

            <EditUser
                open={openUserDialog}
                user={null}
                mode={"add"}
                handleClose={() => setOpenUserDialog(false)}
                updateUsers={getUserList}
            />
        </Dialog>
    );
};

export default NewOrder;
