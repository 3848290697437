import React, { FC } from "react";
import { useRouteError } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const ErrorPage: FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error = useRouteError() as any;
    // eslint-disable-next-line no-console
    console.error(error);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
            }}
        >
            <Typography variant="h2">Oops!</Typography>
            <Typography variant="body1">Sorry, an unexpected error has occured.</Typography>
            <Typography variant="subtitle1">{error.statusText || error.message}</Typography>
        </Box>
    );
};

export default ErrorPage;
