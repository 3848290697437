import { Box, Divider, FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NormalHours } from "interface/hours.interface";
import { FC } from "react";

interface UpdateDayProps {
    dayName: string;
    day: NormalHours;
    update: (key: string, value: string | boolean) => void;
}

const UpdateDay: FC<UpdateDayProps> = (props: UpdateDayProps) => {
    const { dayName, day, update } = props;
    const theme = useTheme();
    return (
        <Box>
            <Divider sx={{ m: theme.spacing(1) }} />

            <Typography variant="subtitle2">{dayName} *</Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                    control={<Switch checked={day.closed} onChange={(e) => update("closed", e.target.checked)} />}
                    label={day.closed ? "Closed" : "Open"}
                    sx={{ pr: theme.spacing(0.5) }}
                />
                <TextField label="Open" type="time" value={day.open} onChange={(e) => update("open", e.target.value)} />
                <TextField
                    label="Close"
                    type="time"
                    value={day.close}
                    onChange={(e) => update("close", e.target.value)}
                />
            </Box>
        </Box>
    );
};

export default UpdateDay;
