import AddIcon from "@mui/icons-material/Add";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { page_getAll } from "api/page.api";
import { Page } from "interface/page.interface";
import { FC, useEffect, useState } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import useSnack from "routes/common/components/ProvideSnack";
import AddPage from "./AddPage";

const Pages: FC = () => {
    const [pages, setPages] = useState<Page[]>([]);
    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    const theme = useTheme();
    const snack = useSnack();
    const navigate = useNavigate();

    const handleGetPages = async () => {
        const response = await page_getAll();
        if (response.status === "success") {
            setPages(response.data.sort((a, b) => (a.order as number) - (b.order as number)));
        } else {
            snack("error", response.status + ": Issue getting pages");
        }
    };

    useEffect(() => {
        handleGetPages();
    }, []);

    return (
        <Box>
            <Typography variant="h4">Page Management</Typography>

            <Box sx={{ mt: theme.spacing(2), display: "flex", flexDirection: "row" }}>
                <Box sx={{ mr: theme.spacing(2) }}>
                    <List
                        sx={{ width: 200, backgroundColor: "background.paper" }}
                        component="nav"
                        subheader={<ListSubheader component="div">Current Pages</ListSubheader>}
                    >
                        {pages.length ? (
                            pages.map((page, index) => (
                                <ListItemButton
                                    selected={selectedIndex === index}
                                    key={page.id}
                                    onClick={() => {
                                        navigate("/admin/pages/" + page.path);
                                        setSelectedIndex(index);
                                    }}
                                >
                                    <ListItemText primary={`${page.order}. ${page.name}`} />
                                </ListItemButton>
                            ))
                        ) : (
                            <ListItem>
                                <ListItemText primary="No Pages to Display" />
                            </ListItem>
                        )}
                        <Divider />
                        <ListItemButton onClick={() => setOpenAddDialog(true)}>
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add New Page" />
                        </ListItemButton>
                    </List>
                </Box>
                <Paper sx={{ p: theme.spacing(2), width: "100%" }}>
                    <Outlet context={{ handleGetPages, pages } satisfies ContextType} />
                </Paper>
            </Box>
            <AddPage open={openAddDialog} handleClose={() => setOpenAddDialog(false)} updatePages={handleGetPages} />
        </Box>
    );
};

export default Pages;

type ContextType = { handleGetPages: () => void; pages: Page[] };

export const useGetPages = () => {
    return useOutletContext<ContextType>();
};
