import CloseIcon from "@mui/icons-material/Close";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MDEditor from "@uiw/react-md-editor";
import { card_patch, card_post } from "api/card.api";
import { upload_image } from "api/file.api";
import { createFilePath } from "functions/files.func";
import { Card, CardCreate, CardUpdate } from "interface/card.interface";
import { FC, useEffect, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";
import { useGetPages } from "./Pages";

interface EditCardProps {
    card: Card | null;
    currentPageId?: string;
    mode: "add" | "edit";
    open: boolean;
    handleClose: () => void;
    updateCards: () => void;
    cardCount: number;
}

const EditCard: FC<EditCardProps> = (props: EditCardProps) => {
    const { card, currentPageId, mode, open, handleClose, updateCards, cardCount } = props;
    const theme = useTheme();
    const snack = useSnack();
    const [PageId, setPageId] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [subtitle, setSubtitle] = useState<string>("");
    const [details, setDetails] = useState<string>("");
    const [price, setPrice] = useState<number>(0);
    const [salePrice, setSalePrice] = useState<number>(0);
    const [priceOverride, setPriceOverride] = useState<string>("");
    const [image, setImage] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const [linkText, setLinkText] = useState<string>("");
    const [link2, setLink2] = useState<string>("");
    const [linkText2, setLinkText2] = useState<string>("");
    const [link3, setLink3] = useState<string>("");
    const [linkText3, setLinkText3] = useState<string>("");
    const [addToThisPage, setAddToThisPage] = useState<boolean>(true);
    const [pageOnly, setPageOnly] = useState<string>("");
    const [order, setOrder] = useState<number | null>(null);
    const [saving, setSaving] = useState<boolean>(false);

    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string>("");

    const canSave = Boolean(title);
    const getPages = useGetPages();

    const handleSetCard = () => {
        setPageId(card?.PageId ?? "");
        setTitle(card?.title ?? "");
        setSubtitle(card?.subtitle ?? "");
        setDetails(card?.details ?? "");
        setPrice(card?.price ?? 0);
        setSalePrice(card?.salePrice ?? 0);
        setPriceOverride(card?.priceOverride ?? "");
        setImage(card?.image ?? "");
        setLink(card?.link ?? "");
        setLinkText(card?.linkText ?? "");
        setLink2(card?.link2 ?? "");
        setLinkText2(card?.linkText2 ?? "");
        setLink3(card?.link3 ?? "");
        setLinkText3(card?.linkText3 ?? "");
        setSelectedImage(null);
        setImagePreview(card?.image ?? "");
        setPageOnly(card?.pageOnlyId ?? "");
        setOrder(card?.order ?? null);
        setSaving(false);
    };

    const handleCancelAndClose = () => {
        handleSetCard();
        handleClose();
    };

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files === null) return;
        const img = e.target.files[0];
        if (img) {
            if (img.size < 3 * 1000000) {
                setSelectedImage(img);
                setImagePreview(URL.createObjectURL(img));
            } else {
                snack("error", "Image too large.");
            }
        } else {
            setSelectedImage(null);
            setImagePreview("");
        }
    };

    const handleSubmit = async () => {
        if (!title) {
            snack("error", "Cards must have a title.");
            return;
        }
        setSaving(true);
        let newImage: string | null = null;
        if (selectedImage) {
            const uploadResponse = await upload_image(selectedImage);
            if (uploadResponse.status === "complete") {
                const filepath = createFilePath(selectedImage.name);
                newImage = filepath;
            }
        }
        if (mode === "add") {
            const newData: CardCreate = {
                title: title,
                order: null,
            };
            if (addToThisPage && currentPageId) {
                newData["PageId"] = currentPageId;
                newData.order = cardCount + 1;
            }
            if (subtitle) newData["subtitle"] = subtitle;
            if (details) newData["details"] = details;
            if (price) newData["price"] = price;
            if (salePrice) newData["salePrice"] = salePrice;
            if (priceOverride) newData["priceOverride"] = priceOverride;
            if (newImage || image) newData["image"] = newImage ?? image;
            if (link) newData["link"] = link;
            if (linkText) newData["linkText"] = linkText;
            if (link2) newData["link2"] = link2;
            if (linkText2) newData["linkText2"] = linkText2;
            if (link3) newData["link3"] = link3;
            if (linkText3) newData["linkText3"] = linkText3;
            if (pageOnly) newData["pageOnlyId"] = pageOnly;

            const response = await card_post(newData);
            if (response.status === "success" && response.data?.id) {
                snack("success", "Created card successfully.");
                updateCards();
                handleCancelAndClose();
            } else {
                snack("error", response.status + ": " + response.message);
            }
        } else if (mode === "edit" && card) {
            const updatedData: CardUpdate = {
                id: card?.id,
                title: title,
                order: order,
            };
            if (PageId) updatedData["PageId"] = PageId;
            if (subtitle) updatedData["subtitle"] = subtitle;
            if (details) updatedData["details"] = details;
            if (price) updatedData["price"] = price;
            if (salePrice) updatedData["salePrice"] = salePrice;
            if (priceOverride) updatedData["priceOverride"] = priceOverride;
            if (newImage || image) updatedData["image"] = newImage ?? image;
            if (link) updatedData["link"] = link;
            if (linkText) updatedData["linkText"] = linkText;
            if (link2) updatedData["link2"] = link2;
            if (linkText2) updatedData["linkText2"] = linkText2;
            if (link3) updatedData["link3"] = link3;
            if (linkText3) updatedData["linkText3"] = linkText3;
            if (pageOnly) updatedData["pageOnlyId"] = pageOnly;
            const response3 = await card_patch(updatedData);
            setSaving(false);
            if (response3.status === "success" && response3.data![0] === 1) {
                snack("success", "Updated card successfully.");
                updateCards();
                handleCancelAndClose();
            } else {
                snack("error", response3.status + ": " + response3.message);
            }
        }
    };

    useEffect(() => {
        handleSetCard();
    }, [card]);

    return (
        <Dialog open={open}>
            <IconButton onClick={handleCancelAndClose} size="large" sx={{ position: "absolute", right: 0, top: 0 }}>
                <CloseIcon color="error" sx={{ fontSize: 24 }} />
            </IconButton>
            <DialogTitle>{mode === "add" ? "Add" : "Edit"} Card</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1">
                    {mode === "add" ? "Add a new card that can be used on any page." : "Update card details."}
                </Typography>
                <TextField
                    variant="standard"
                    fullWidth
                    required
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }}
                />
                <TextField
                    variant="standard"
                    fullWidth
                    label="Subtitle"
                    value={subtitle}
                    onChange={(e) => setSubtitle(e.target.value)}
                    sx={{ mb: theme.spacing(2) }}
                />
                <Typography variant="body2">Details</Typography>
                <Box sx={{ height: "150px" }}>
                    <MDEditor
                        value={details}
                        onChange={(value) => setDetails(value as string)}
                        height="150px"
                        preview="edit"
                    />
                </Box>
                <Box sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }}>
                    <TextField
                        label="Price"
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(Number(e.target.value))}
                        sx={{ mr: theme.spacing(1) }}
                        helperText={"Set Price above zero (0) to activate."}
                    />
                    <TextField
                        label="Sale Price"
                        value={salePrice}
                        type="number"
                        onChange={(e) => setSalePrice(Number(e.target.value))}
                        sx={{ ml: theme.spacing(1) }}
                        helperText={"Set Sale Price above zero (0) to activate."}
                    />
                </Box>
                <TextField
                    fullWidth
                    label="Price Override"
                    value={priceOverride}
                    onChange={(e) => setPriceOverride(e.target.value)}
                    sx={{ mb: theme.spacing(2) }}
                    helperText={"Set this value to override price as number with a line of text."}
                />
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography variant="body1">Image</Typography>
                    <Typography variant="subtitle2">Optional, Max image size: 3MB</Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        backgroundColor: "#eee",
                        borderRadius: theme.spacing(1),
                        p: theme.spacing(2),
                    }}
                >
                    <Box>
                        <Button
                            variant="contained"
                            component="label"
                            endIcon={<PhotoCameraIcon />}
                            sx={{ width: "auto" }}
                        >
                            {imagePreview ? "Change Image" : "Select Image"}
                            <input
                                hidden
                                accept="image/*"
                                type="file"
                                name="uplink/post"
                                onChange={handleChangeImage}
                            />
                            <input hidden type="text" name="folder" value="uplink/post" readOnly />
                        </Button>
                    </Box>
                    {imagePreview ? (
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                mt: theme.spacing(2),
                            }}
                        >
                            <Box
                                data-testid="post-image"
                                component="img"
                                src={imagePreview}
                                sx={{
                                    height: "150px",
                                    width: "150px",
                                    objectFit: "contain",
                                    mr: theme.spacing(1),
                                    backgroundColor: "#fff",
                                }}
                            />
                            <Typography variant="body2">{selectedImage?.name}</Typography>
                        </Box>
                    ) : null}
                </Box>
                <TextField
                    fullWidth
                    label="Link"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }}
                />
                <TextField
                    fullWidth
                    label="Link Text"
                    value={linkText}
                    onChange={(e) => setLinkText(e.target.value)}
                    sx={{ mb: theme.spacing(2) }}
                    helperText={"This changes what the link will display as and makes the display cleaner."}
                />
                <TextField
                    fullWidth
                    label="Link 2"
                    value={link2}
                    onChange={(e) => setLink2(e.target.value)}
                    sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }}
                />
                <TextField
                    fullWidth
                    label="Link Text 2"
                    value={linkText2}
                    onChange={(e) => setLinkText2(e.target.value)}
                    sx={{ mb: theme.spacing(2) }}
                    helperText={"This changes what the link will display as and makes the display cleaner."}
                />
                <TextField
                    fullWidth
                    label="Link 3"
                    value={link3}
                    onChange={(e) => setLink3(e.target.value)}
                    sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }}
                />
                <TextField
                    fullWidth
                    label="Link Text 3"
                    value={linkText3}
                    onChange={(e) => setLinkText3(e.target.value)}
                    sx={{ mb: theme.spacing(2) }}
                    helperText={"This changes what the link will display as and makes the display cleaner."}
                />
                <FormControl fullWidth>
                    <InputLabel id="select-label">Select Page for Card</InputLabel>
                    <Select
                        labelId="select-label"
                        id="select"
                        value={pageOnly}
                        label="Select Page for Card"
                        onChange={(e) => setPageOnly(e.target.value)}
                    >
                        <MenuItem value="">Available to All</MenuItem>
                        {getPages.pages.map((page) => (
                            <MenuItem key={page.id} value={page.id}>
                                {page.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {mode === "add" && (
                    <FormControlLabel
                        control={
                            <Switch checked={addToThisPage} onChange={(e) => setAddToThisPage(e.target.checked)} />
                        }
                        label="Add to This Page"
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancelAndClose}>
                    Cancel
                </Button>
                <Button disabled={!canSave} variant="contained" onClick={handleSubmit}>
                    {saving ? <CircularProgress sx={{ height: 24, width: 24, color: "grey" }} /> : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditCard;
