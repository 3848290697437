import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { order_delete, order_getAll } from "api/order.api";
import { OrderPopulated } from "interface/order.interface";
import { FC, useEffect, useMemo, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";
import EditOrder from "./components/EditOrder";
import NewOrder from "./components/NewOrder";
import OrderSimpleView from "./simple/OrderSimpleView";
import OrderTableView from "./table/OrderTableView";

const Orders: FC = () => {
    const snack = useSnack();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const [view, setView] = useState<"simple" | "table">("simple");
    const [orders, setOrders] = useState<OrderPopulated[]>([]);
    const [selectedOrder, setSelectedOrder] = useState<OrderPopulated | null>(null);
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const openEdit = Boolean(selectedOrder);

    const filteredOrders = useMemo(() => {
        let results: OrderPopulated[] = [];
        if (showCompleted === true) {
            results = [...orders];
        } else {
            results = [...orders.filter((order) => order.archived === false)];
        }
        if (search.length > 0) {
            return results.filter((order) => order.User.name.toLowerCase().includes(search.toLowerCase()));
        }
        return results;
    }, [orders, showCompleted, search]);

    const handleGetOrders = async () => {
        const response = await order_getAll();
        if (response.status === "success") {
            setOrders(response.data);
        } else {
            snack("error", "Error getting orders");
        }
    };

    const handleDelete = async (id: string) => {
        const response = await order_delete(id);
        if (response.status === "success") {
            snack("success", "Order deleted.");
            handleGetOrders();
        } else snack("error", "Error deleting order.");
    };

    useEffect(() => {
        handleGetOrders();
    }, []);

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", m: theme.spacing(1) }}>
                <Typography variant={mobile ? "h6" : "h4"}>Orders</Typography>

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {showCompleted ? (
                        <Button onClick={() => setShowCompleted(false)} variant="contained" size="small">
                            <CheckBoxIcon sx={{ mr: mobile ? 0 : 1 }} fontSize="small" />
                            {mobile ? null : "Show Completed"}
                        </Button>
                    ) : (
                        <Button onClick={() => setShowCompleted(true)} variant="outlined" size="small">
                            <CheckBoxOutlineBlankIcon sx={{ mr: mobile ? 0 : 1 }} fontSize="small" />
                            {mobile ? null : "Show Completed"}
                        </Button>
                    )}
                    <Button onClick={() => setOpenNew(true)} variant="contained" size="small" sx={{ ml: 1 }}>
                        <AddIcon sx={{ mr: mobile ? 0 : 1 }} fontSize="small" />
                        {mobile ? null : "New Order"}
                    </Button>
                </Box>
            </Box>
            <Box sx={{ m: 1, display: "flex", justifyContent: "space-between" }}>
                {mobile ? (
                    <Box></Box>
                ) : (
                    <ToggleButtonGroup
                        size="small"
                        color="primary"
                        value={view}
                        exclusive
                        onChange={(event, value) => setView(value)}
                    >
                        <ToggleButton value="simple">Simple</ToggleButton>
                        <ToggleButton value="table">Table</ToggleButton>
                    </ToggleButtonGroup>
                )}
                <TextField
                    component={Paper}
                    size={"small"}
                    fullWidth={!!mobile}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setSearch("")}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </Box>
            {view === "simple" ? (
                <OrderSimpleView
                    orders={filteredOrders}
                    edit={(order: OrderPopulated) => {
                        setSelectedOrder(order);
                    }}
                    deleteItem={handleDelete}
                    update={handleGetOrders}
                />
            ) : (
                <OrderTableView
                    orders={filteredOrders}
                    edit={(order: OrderPopulated) => {
                        setSelectedOrder(order);
                    }}
                    deleteItem={handleDelete}
                    update={handleGetOrders}
                />
            )}

            <NewOrder open={openNew} close={() => setOpenNew(false)} update={handleGetOrders} />
            {selectedOrder && (
                <EditOrder
                    open={openEdit}
                    order={selectedOrder}
                    close={() => setSelectedOrder(null)}
                    update={handleGetOrders}
                />
            )}
        </Box>
    );
};

export default Orders;
