import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { hours_activate, hours_delete, hours_getAll } from "api/hours.api";
import { updatedHours_delete, updatedHours_getAll } from "api/updatedHours.api";
import { Hours } from "interface/hours.interface";
import { UpdatedHours } from "interface/updatedHours.interface";
import { FC, useEffect, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";
import EditHours from "./EditHours";
import EditUpdatedHours from "./EditUpdatedHours";
import HoursCard from "./HoursCard";
import UpdatedHoursCard from "./UpdatedHoursCard";

const HoursAdmin: FC = () => {
    const [hours, setHours] = useState<Hours[]>([]);
    const [updatedHours, setUpdatedHours] = useState<UpdatedHours[]>([]);
    const [openEditHours, setOpenEditHours] = useState<boolean>(false);
    const [selectedHours, setSelectedHours] = useState<undefined | Hours>(undefined);
    const [openEditUpdatedHours, setOpenEditUpdatedHours] = useState<boolean>(false);
    const [selectedUpdatedHours, setSelectedUpdatedHours] = useState<undefined | UpdatedHours>(undefined);
    const [mode, setMode] = useState<"add" | "edit">("add");
    const theme = useTheme();
    const snack = useSnack();

    const handleGetHours = async () => {
        const response = await hours_getAll();
        if (response.status === "success") {
            setHours(response.data!);
        } else {
            snack("error", "Error getting Hours. " + response.message!);
        }
    };

    const handleGetUpdatedHours = async () => {
        const response = await updatedHours_getAll();
        if (response.status === "success") {
            setUpdatedHours(response.data!);
        } else {
            snack("error", "Error getting Updated Hours. " + response.message!);
        }
    };

    const handleEditHours = async (index: number) => {
        setSelectedHours(hours[index]);
        setMode("edit");
        setOpenEditHours(true);
    };

    const handleActivateHours = async (index: number) => {
        const response = await hours_activate(hours[index].id);
        if (response.status === "success") {
            snack("success", `Successfully activated ${hours[index].name} store hours.`);
            handleGetHours();
        } else {
            snack("error", "Error submitting changes: " + response.message!);
        }
    };

    const handleDeleteHours = async (index: number) => {
        const response = await hours_delete(hours[index].id);
        if (response.status === "success") {
            snack("success", `Successfully deleted ${hours[index].name} store hours.`);
            handleGetHours();
        } else {
            snack("error", "Error submitting changes: " + response.message!);
        }
    };

    const handleEditUpdatedHours = async (index: number) => {
        setSelectedUpdatedHours(updatedHours[index]);
        setMode("edit");
        setOpenEditUpdatedHours(true);
    };

    const handleDeleteUpdatedHours = async (index: number) => {
        const response = await updatedHours_delete(updatedHours[index].id);
        if (response.status === "success") {
            snack("success", `Successfully deleted updated hours for ${updatedHours[index].date}.`);
            handleGetUpdatedHours();
        } else {
            snack("error", "Error submitting changes: " + response.message);
        }
    };

    useEffect(() => {
        handleGetHours();
        handleGetUpdatedHours();
    }, []);

    return (
        <Box>
            <Typography variant="h4">Store Hours</Typography>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-around",
                    mt: theme.spacing(2),
                }}
            >
                <Paper sx={{ width: { md: 400, lg: 700 }, p: theme.spacing(2), mb: theme.spacing(2) }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Typography variant="h6">Normal Hours</Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                setMode("add");
                                setOpenEditHours(true);
                            }}
                        >
                            Hours
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", flexFlow: "row wrap", justifyContent: "center" }}>
                        {hours.length > 0 ? (
                            hours.map((schedule, index) => (
                                <HoursCard
                                    index={index}
                                    key={schedule.name}
                                    hours={schedule}
                                    editHours={handleEditHours}
                                    activateHours={handleActivateHours}
                                    deleteHours={handleDeleteHours}
                                />
                            ))
                        ) : (
                            <Typography>Nothing to show here</Typography>
                        )}
                    </Box>
                </Paper>
                <Paper sx={{ width: { md: 400, lg: 700 }, p: theme.spacing(2), mb: theme.spacing(2) }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Typography variant="h6">Updated Hours</Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                setMode("add");
                                setOpenEditUpdatedHours(true);
                            }}
                        >
                            Updates
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", flexFlow: "row wrap", justifyContent: "center" }}>
                        {updatedHours.length > 0 ? (
                            updatedHours.map((date, index) => (
                                <UpdatedHoursCard
                                    index={index}
                                    key={date.date}
                                    updatedHours={date}
                                    editUpdatedHours={handleEditUpdatedHours}
                                    deleteUpdatedHours={handleDeleteUpdatedHours}
                                />
                            ))
                        ) : (
                            <Typography>Nothing to show here</Typography>
                        )}
                    </Box>
                </Paper>
            </Box>
            <EditHours
                mode={mode}
                open={openEditHours}
                hours={selectedHours}
                updateHours={handleGetHours}
                handleClose={() => setOpenEditHours(false)}
            />
            <EditUpdatedHours
                mode={mode}
                open={openEditUpdatedHours}
                updatedHours={selectedUpdatedHours}
                updateUpdatedHours={handleGetUpdatedHours}
                handleClose={() => setOpenEditUpdatedHours(false)}
            />
        </Box>
    );
};

export default HoursAdmin;
