import { Box, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { page_getByPath } from "api/page.api";
import { PagePopulated } from "interface/page.interface";
import { FC, useEffect, useState } from "react";
import AnnouncementCard from "routes/common/components/AnnouncementCard";
import Loading from "routes/common/components/Loading";
import TopCard from "routes/common/components/TopCard";
import laneImage from "../../SSO-BowlingLane.jpg";
import BioCard from "./BioCard";

const AboutUs: FC = () => {
    const theme = useTheme();
    const [pageData, setPageData] = useState<PagePopulated | null>(null);

    const loadPageData = async () => {
        const response = await page_getByPath("about");
        setPageData(response.data!);
    };

    useEffect(() => {
        loadPageData();
    }, []);

    return pageData ? (
        <Box
            sx={{
                backgroundImage: `url(${laneImage})`,
                backgroundSize: "cover",
            }}
        >
            {pageData.announcementText ? (
                <AnnouncementCard title={pageData.announcementTitle} text={pageData.announcementText} />
            ) : null}
            <Container maxWidth="md" sx={{ p: theme.spacing(2) }}>
                {pageData.bannerText ? <TopCard text={pageData.bannerText} /> : null}
                <Box
                    sx={{
                        display: "flex",
                        flexFlow: "row wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(200, 200, 200, 0.8)",
                        borderRadius: { sx: 0, sm: 4 },
                        minHeight: 500,
                        mt: theme.spacing(2),
                    }}
                >
                    {pageData.Cards ? pageData.Cards.map((card) => <BioCard key={card.id} card={card} />) : null}
                </Box>
            </Container>
        </Box>
    ) : (
        <Loading />
    );
};

export default AboutUs;
