import { API_URL } from "config/api";
import { Page, PageCreation, PageList, PagePopulated, PageUpdate } from "interface/page.interface";

export const PAGE_URL = `${API_URL}/page`;

export const page_getAll = async (): Promise<{ status: string; data: [PageList]; message: string }> => {
    const response = await fetch(`${PAGE_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const page_getByPath = async (
    name: string,
): Promise<{ status: string; data?: PagePopulated; message?: string }> => {
    const response = await fetch(`${PAGE_URL}/${name}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const page_post = async (
    newDocument: PageCreation,
): Promise<{ status: string; data?: Page; message: string }> => {
    const response = await fetch(`${PAGE_URL}`, {
        method: "POST",
        body: JSON.stringify(newDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const page_patch = async (
    updatedDocument: PageUpdate,
): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${PAGE_URL}`, {
        method: "PATCH",
        body: JSON.stringify(updatedDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const page_delete = async (id: string): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${PAGE_URL}`, {
        method: "DELETE",
        body: JSON.stringify({ id: id }),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};
