import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";

const LoginButton: FC = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = () => loginWithRedirect();

    return (
        <Button variant="contained" onClick={handleLogin}>
            Login
        </Button>
    );
};

export default LoginButton;
