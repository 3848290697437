import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import patchNotes from "./patchnotes.md";

const AdminPatchNotes = () => {
    const theme = useTheme();
    const [patchNotesText, setPatchNotesText] = useState<string>("");

    useEffect(() => {
        fetch(patchNotes)
            .then((res) => res.text())
            .then((text) => setPatchNotesText(text));
    }, []);

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: theme.spacing(2) }}>
                <Typography variant="h4">Patch Notes</Typography>
            </Box>
            <Box>
                <ReactMarkdown>{patchNotesText}</ReactMarkdown>
            </Box>
        </Box>
    );
};

export default AdminPatchNotes;
