const fileServer = "https://weinhauscreations.com/api/file-server";

export const upload_image = async (file: File): Promise<{ status: string; message?: string; error: any }> => {
    const data = new FormData();
    data.append("file", file);
    data.append("path", "tenback");
    const response = await fetch(`${fileServer}/upload`, {
        method: "POST",
        body: data,
    });
    return response.json();
};
