import { Box, Button, Card, CardActions, CardContent, Divider, Menu, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { handleGetNormalHoursFromTable } from "functions/hours.func";
import { Hours, NormalHours } from "interface/hours.interface";
import { FC, useState } from "react";

interface HoursCardProps {
    index: number;
    hours: Hours;
    editHours: (index: number) => void;
    activateHours: (index: number) => void;
    deleteHours: (index: number) => void;
}

interface DayReadoutProps {
    day: NormalHours;
}

const DayReadout: FC<DayReadoutProps> = (props: DayReadoutProps) => {
    const { day } = props;
    const theme = useTheme();

    return day.closed ? (
        <Typography color="error" variant="body1" sx={{ ml: theme.spacing(2) }}>
            Closed
        </Typography>
    ) : (
        <Typography color="primary" variant="body1" sx={{ ml: theme.spacing(2) }}>
            {day.open} &mdash; {day.close}
        </Typography>
    );
};

const HoursCard: FC<HoursCardProps> = (props: HoursCardProps) => {
    const { index, hours, editHours, activateHours, deleteHours } = props;
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const handleActivate = () => {
        setAnchorEl(null);
        activateHours(index);
    };

    const handleEdit = () => {
        setAnchorEl(null);

        editHours(index);
    };

    const handleDelete = () => {
        setAnchorEl(null);
        deleteHours(index);
    };

    return (
        <Card sx={{ width: 300, m: theme.spacing(2) }}>
            <CardContent>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">{hours.name}</Typography>
                    {hours.active ? (
                        <Typography variant="subtitle2" color="primary">
                            Active
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="error">
                            Inactive
                        </Typography>
                    )}
                </Box>
                <Divider sx={{ m: theme.spacing(1) }} />
                <Box
                    sx={{
                        display: "flex",
                        ml: theme.spacing(3),
                        mr: theme.spacing(3),
                        justifyContent: "space-between",
                    }}
                >
                    <Typography>Monday</Typography>
                    <DayReadout
                        day={handleGetNormalHoursFromTable(hours.mondayClosed, hours.mondayOpen, hours.mondayClose)}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        ml: theme.spacing(3),
                        mr: theme.spacing(3),
                        justifyContent: "space-between",
                    }}
                >
                    <Typography>Tuesday</Typography>
                    <DayReadout
                        day={handleGetNormalHoursFromTable(hours.tuesdayClosed, hours.tuesdayOpen, hours.tuesdayClose)}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        ml: theme.spacing(3),
                        mr: theme.spacing(3),
                        justifyContent: "space-between",
                    }}
                >
                    <Typography>Wednesday</Typography>
                    <DayReadout
                        day={handleGetNormalHoursFromTable(
                            hours.wednesdayClosed,
                            hours.wednesdayOpen,
                            hours.wednesdayClose,
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        ml: theme.spacing(3),
                        mr: theme.spacing(3),
                        justifyContent: "space-between",
                    }}
                >
                    <Typography>Thursday</Typography>
                    <DayReadout
                        day={handleGetNormalHoursFromTable(
                            hours.thursdayClosed,
                            hours.thursdayOpen,
                            hours.thursdayClose,
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        ml: theme.spacing(3),
                        mr: theme.spacing(3),
                        justifyContent: "space-between",
                    }}
                >
                    <Typography>Friday</Typography>
                    <DayReadout
                        day={handleGetNormalHoursFromTable(hours.fridayClosed, hours.fridayOpen, hours.fridayClose)}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        ml: theme.spacing(3),
                        mr: theme.spacing(3),
                        justifyContent: "space-between",
                    }}
                >
                    <Typography>Saturday</Typography>
                    <DayReadout
                        day={handleGetNormalHoursFromTable(
                            hours.saturdayClosed,
                            hours.saturdayOpen,
                            hours.saturdayClose,
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        ml: theme.spacing(3),
                        mr: theme.spacing(3),
                        justifyContent: "space-between",
                    }}
                >
                    <Typography>Sunday</Typography>
                    <DayReadout
                        day={handleGetNormalHoursFromTable(hours.sundayClosed, hours.sundayOpen, hours.sundayClose)}
                    />
                </Box>
            </CardContent>
            <CardActions>
                <Button
                    id={`options-${hours.name}`}
                    aria-controls={openMenu ? "options-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                    Options
                </Button>
                <Menu id="options-menu" anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
                    <MenuItem onClick={handleActivate} disabled={hours.active}>
                        Activate
                    </MenuItem>
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <MenuItem onClick={handleDelete} sx={{ color: "red" }} disabled={hours.active}>
                        Delete
                    </MenuItem>
                </Menu>
            </CardActions>
        </Card>
    );
};

export default HoursCard;
