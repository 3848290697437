import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { page_getByPath } from "api/page.api";
import { PagePopulated } from "interface/page.interface";
import { FC, useEffect, useState } from "react";
import AnnouncementCard from "routes/common/components/AnnouncementCard";
import Loading from "routes/common/components/Loading";
import TopCard from "routes/common/components/TopCard";
import laneImage from "../../SSO-BowlingLane.jpg";
import ContactCard from "./ContactCard";
import HoursTable from "./HoursTable";
import UpdatedHoursTable from "./UpdatedHoursTable";

const Contact: FC = () => {
    const theme = useTheme();

    const [pageData, setPageData] = useState<PagePopulated | null>(null);

    const retrievePageData = async () => {
        const response = await page_getByPath("contact-us");
        setPageData(response.data!);
    };

    useEffect(() => {
        retrievePageData();
    }, []);

    return pageData ? (
        <Box
            sx={{
                backgroundImage: `url(${laneImage})`,
                backgroundSize: "cover",
            }}
        >
            {pageData.announcementText ? (
                <AnnouncementCard title={pageData.announcementTitle} text={pageData.announcementText} />
            ) : null}
            <Container maxWidth="md" sx={{ p: theme.spacing(2) }}>
                {pageData.bannerText ? <TopCard text={pageData.bannerText} /> : null}
                <Box
                    sx={{
                        display: "flex",
                        flexFlow: "row wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(200, 200, 200, 0.8)",
                        borderRadius: { sx: 0, sm: 4 },
                        minHeight: 500,
                        mt: theme.spacing(2),
                    }}
                >
                    <Card
                        sx={{
                            minHeight: 144,
                            m: theme.spacing(1),
                            width: 350,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CardContent>
                            <Typography variant="h4" sx={{ textAlign: "center" }}>
                                Normal Hours
                            </Typography>
                            <HoursTable />
                        </CardContent>
                    </Card>
                    <Card
                        sx={{
                            minHeight: 144,
                            m: theme.spacing(1),
                            width: 350,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <CardContent>
                            <Typography variant="h4" sx={{ textAlign: "center" }}>
                                Updated Hours
                            </Typography>
                            <UpdatedHoursTable />
                        </CardContent>
                    </Card>
                    {pageData.Cards ? pageData.Cards.map((card) => <ContactCard key={card.id} card={card} />) : null}
                </Box>
            </Container>
        </Box>
    ) : (
        <Loading />
    );
};

export default Contact;
