import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { hours_patch, hours_post } from "api/hours.api";
import { Hours, NormalHours } from "interface/hours.interface";
import { FC, useEffect, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";
import UpdateDay from "./UpdateDay";

interface EditHoursProps {
    mode: "add" | "edit";
    open: boolean;
    hours?: Hours;
    updateHours: () => void;
    handleClose: () => void;
}

const defaultDay: NormalHours = {
    closed: true,
    open: "00:00",
    close: "00:00",
};

const EditHours: FC<EditHoursProps> = (props: EditHoursProps) => {
    const { mode, open, hours, updateHours, handleClose } = props;
    const theme = useTheme();
    const snack = useSnack();

    const [hoursName, setHoursName] = useState<string>("");
    const [active, setActive] = useState<boolean>(false);
    const [monday, setMonday] = useState<NormalHours>(defaultDay);
    const [tuesday, setTuesday] = useState<NormalHours>(defaultDay);
    const [wednesday, setWednesday] = useState<NormalHours>(defaultDay);
    const [thursday, setThursday] = useState<NormalHours>(defaultDay);
    const [friday, setFriday] = useState<NormalHours>(defaultDay);
    const [saturday, setSaturday] = useState<NormalHours>(defaultDay);
    const [sunday, setSunday] = useState<NormalHours>(defaultDay);

    const handleUpdateMonday = (key: string, value: string | boolean) => {
        const updatedValue = { ...monday };
        updatedValue[key] = value;
        setMonday(updatedValue);
    };
    const handleUpdateTuesday = (key: string, value: string | boolean) => {
        const updatedValue = { ...tuesday };
        updatedValue[key] = value;
        setTuesday(updatedValue);
    };
    const handleUpdateWednesday = (key: string, value: string | boolean) => {
        const updatedValue = { ...wednesday };
        updatedValue[key] = value;
        setWednesday(updatedValue);
    };
    const handleUpdateThursday = (key: string, value: string | boolean) => {
        const updatedValue = { ...thursday };
        updatedValue[key] = value;
        setThursday(updatedValue);
    };
    const handleUpdateFriday = (key: string, value: string | boolean) => {
        const updatedValue = { ...friday };
        updatedValue[key] = value;
        setFriday(updatedValue);
    };
    const handleUpdateSaturday = (key: string, value: string | boolean) => {
        const updatedValue = { ...saturday };
        updatedValue[key] = value;
        setSaturday(updatedValue);
    };
    const handleUpdateSunday = (key: string, value: string | boolean) => {
        const updatedValue = { ...sunday };
        updatedValue[key] = value;
        setSunday(updatedValue);
    };

    const handleLoad = () => {
        if (hours) {
            setHoursName(hours.name);
            setActive(hours.active);
            setMonday({
                closed: hours.mondayClosed,
                open: hours.mondayOpen,
                close: hours.mondayClose,
            });
            setTuesday({
                closed: hours.tuesdayClosed,
                open: hours.tuesdayOpen,
                close: hours.tuesdayClose,
            });
            setWednesday({
                closed: hours.wednesdayClosed,
                open: hours.wednesdayOpen,
                close: hours.wednesdayClose,
            });
            setThursday({
                closed: hours.thursdayClosed,
                open: hours.thursdayOpen,
                close: hours.thursdayClose,
            });
            setFriday({
                closed: hours.fridayClosed,
                open: hours.fridayOpen,
                close: hours.fridayClose,
            });
            setSaturday({
                closed: hours.saturdayClosed,
                open: hours.saturdayOpen,
                close: hours.saturdayClose,
            });
            setSunday({
                closed: hours.sundayClosed,
                open: hours.sundayOpen,
                close: hours.sundayClose,
            });
        }
    };

    const handleCancel = () => {
        setHoursName("");
        setActive(false);
        setMonday(defaultDay);
        setTuesday(defaultDay);
        setWednesday(defaultDay);
        setThursday(defaultDay);
        setFriday(defaultDay);
        setSaturday(defaultDay);
        setSunday(defaultDay);
        handleClose();
    };

    const handleSubmit = async () => {
        const data: Hours = {
            name: hoursName,
            active: active,
            mondayClosed: monday.closed,
            mondayOpen: monday.open,
            mondayClose: monday.close,
            tuesdayClosed: tuesday.closed,
            tuesdayOpen: tuesday.open,
            tuesdayClose: tuesday.close,
            wednesdayClosed: wednesday.closed,
            wednesdayOpen: wednesday.open,
            wednesdayClose: wednesday.close,
            thursdayClosed: thursday.closed,
            thursdayOpen: thursday.open,
            thursdayClose: thursday.close,
            fridayClosed: friday.closed,
            fridayOpen: friday.open,
            fridayClose: friday.close,
            saturdayClosed: saturday.closed,
            saturdayOpen: saturday.open,
            saturdayClose: saturday.close,
            sundayClosed: sunday.closed,
            sundayOpen: sunday.open,
            sundayClose: sunday.close,
        } as Hours;
        if (hours) {
            data["id"] = hours.id;
            const response1 = await hours_patch(data);
            if (response1.status === "success" && response1.data![0] === 1) {
                snack("success", "Hours successfully saved");
                updateHours();
                handleCancel();
            } else {
                snack("error", "Error saving hours. " + response1.message);
            }
        } else {
            const response2 = await hours_post(data);
            if (response2.status === "success" && response2.data!.id) {
                snack("success", "Hours successfully saved");
                updateHours();
                handleCancel();
            } else {
                snack("error", "Error saving hours. " + response2.message);
            }
        }
    };

    useEffect(() => {
        handleLoad();
    }, [hours]);

    return (
        <Dialog open={open}>
            <IconButton onClick={handleCancel} size="large" sx={{ position: "absolute", right: 0, top: 0 }}>
                <CloseIcon color="error" sx={{ fontSize: 24 }} />
            </IconButton>
            <DialogTitle>{mode === "add" ? "Add" : "Edit"} Hours</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1">
                    {mode === "add" ? "Add a new hours set for the store." : "Update the hours set for the store."}
                </Typography>
                <TextField
                    label="Name"
                    value={hoursName}
                    sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }}
                    onChange={(e) => setHoursName(e.target.value)}
                    required
                />
                {/* <FormControlLabel
                    control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
                    label="Active"
                    required
                /> */}

                <UpdateDay dayName="Monday" day={monday} update={handleUpdateMonday} />
                <UpdateDay dayName="Tuesday" day={tuesday} update={handleUpdateTuesday} />
                <UpdateDay dayName="Wednesday" day={wednesday} update={handleUpdateWednesday} />
                <UpdateDay dayName="Thursday" day={thursday} update={handleUpdateThursday} />
                <UpdateDay dayName="Friday" day={friday} update={handleUpdateFriday} />
                <UpdateDay dayName="Saturday" day={saturday} update={handleUpdateSaturday} />
                <UpdateDay dayName="Sunday" day={sunday} update={handleUpdateSunday} />
                <Divider sx={{ m: theme.spacing(1) }} />
                <Box sx={{ mt: theme.spacing(2), display: "flex", justifyContent: "space-around" }}>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Save
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default EditHours;
