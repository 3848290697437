import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, List, ListItem, ListItemText, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { footerLink_delete, footerLink_getAll, footerLink_move } from "api/footerLink.api";
import { FooterLink, FooterLinkMove, TFooterLinkCategory } from "interface/footerLink.interface";
import { FC, useEffect, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";
import AddLink from "./AddLink";
import EditLink from "./EditLink";

const FooterLinks: FC = () => {
    const theme = useTheme();
    const snack = useSnack();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    const [socialLinks, setSocialLinks] = useState<FooterLink[]>([]);
    const [afflLinks, setAfflLinks] = useState<FooterLink[]>([]);

    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [selectedLink, setSelectedLink] = useState<FooterLink | null>(null);
    const openEdit = Boolean(selectedLink);

    const loadLinks = async () => {
        const response = await footerLink_getAll();
        if (response.status === "success") {
            setSocialLinks(
                response.data.filter((link) => link.category === "social").sort((a, b) => a.order - b.order),
            );
            setAfflLinks(
                response.data.filter((link) => link.category === "affiliate").sort((a, b) => a.order - b.order),
            );
        } else {
            snack("error", "Error loading links");
        }
    };

    const handleEdit = (footerLink: FooterLink) => {
        setSelectedLink(footerLink);
    };

    const handleDelete = async (id: string) => {
        const response = await footerLink_delete(id);
        if (response.status === "success") {
            snack("success", "Deleted link.");
            loadLinks();
        } else {
            snack("error", "Error deleting link.");
        }
    };

    const handleMove = async (id: string, category: TFooterLinkCategory, currentOrder: number, targetOrder: number) => {
        const data: FooterLinkMove = {
            id: id,
            currentOrder: currentOrder,
            targetOrder: targetOrder,
            category: category,
        };
        const response = await footerLink_move(data);
        if (response.status !== "success" || response.data[0] !== 2) {
            snack("error", "Error updating order.");
            return;
        }
        loadLinks();
    };

    useEffect(() => {
        loadLinks();
    }, []);

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant={mobile ? "h6" : "h4"}>Footer Links</Typography>
                <Button onClick={() => setOpenAdd(true)}>Add Link</Button>
            </Box>
            <Paper sx={{ p: mobile ? 1 : 2, mt: 2 }}>
                <Typography variant="h6">Social Media</Typography>
                <List>
                    {socialLinks.map((link) => (
                        <ListItem
                            sx={{ backgroundColor: theme.palette.grey[200], mt: 0.5 }}
                            key={link.id}
                            secondaryAction={
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <IconButton onClick={() => handleEdit(link)} size="small">
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(link.id)} size="small">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            }
                        >
                            <Box sx={{ display: "flex", flexDirection: "row", mr: 2 }}>
                                <IconButton
                                    disabled={link.order === 1}
                                    onClick={() => handleMove(link.id, link.category, link.order, link.order - 1)}
                                    size="small"
                                >
                                    <ArrowUpwardIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    disabled={link.order === socialLinks.length}
                                    onClick={() => handleMove(link.id, link.category, link.order, link.order + 1)}
                                    size="small"
                                >
                                    <ArrowDownwardIcon fontSize="small" />
                                </IconButton>
                            </Box>
                            <ListItemText
                                primary={link.display}
                                secondary={link.link}
                                secondaryTypographyProps={{ noWrap: true }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
            <Paper sx={{ p: mobile ? 1 : 2, mt: 2 }}>
                <Typography variant="h6">Affiliate</Typography>
                <List>
                    {afflLinks.map((link) => (
                        <ListItem
                            sx={{ backgroundColor: theme.palette.grey[200], mt: 0.5 }}
                            key={link.id}
                            secondaryAction={
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <IconButton onClick={() => handleEdit(link)} size="small">
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(link.id)} size="small">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            }
                        >
                            <Box sx={{ display: "flex", flexDirection: "row", mr: 2 }}>
                                <IconButton
                                    disabled={link.order === 1}
                                    onClick={() => handleMove(link.id, link.category, link.order, link.order - 1)}
                                    size="small"
                                >
                                    <ArrowUpwardIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    disabled={link.order === afflLinks.length}
                                    onClick={() => handleMove(link.id, link.category, link.order, link.order + 1)}
                                    size="small"
                                >
                                    <ArrowDownwardIcon fontSize="small" />
                                </IconButton>
                            </Box>
                            <ListItemText
                                primary={link.display}
                                secondary={link.link}
                                secondaryTypographyProps={{ noWrap: true }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
            <AddLink
                open={openAdd}
                close={() => setOpenAdd(false)}
                update={loadLinks}
                lengthAffl={afflLinks.length}
                lengthSocial={socialLinks.length}
            />
            {selectedLink && (
                <EditLink
                    footerLink={selectedLink}
                    open={openEdit}
                    close={() => setSelectedLink(null)}
                    update={loadLinks}
                />
            )}
        </Box>
    );
};

export default FooterLinks;
