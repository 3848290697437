import { TableCell } from "@mui/material";
import { NormalHours } from "interface/hours.interface";

export const handleGetNormalHoursFromTable = (closed: boolean, open: string, close: string): NormalHours => {
    const normalHours: NormalHours = {
        closed: closed,
        open: open,
        close: close,
    };
    return normalHours;
};

export const handleGetDayHoursTableCell = (closed: boolean, open: string, close: string) => {
    return <TableCell>{closed ? <span style={{ color: "red" }}>Closed</span> : `${open} - ${close}`}</TableCell>;
};
