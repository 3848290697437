import { useAuth0 } from "@auth0/auth0-react";
import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar,
    Avatar,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
} from "@mui/material";
import { user_login } from "api/user.api";
import { useUser, userProfileDefaultValue } from "hooks/User";
import { PageList } from "interface/page.interface";
import { Auth0User } from "interface/user.interface";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TenBackLogoWide from "../../../images/tenback-logo-wide-vector.png";
import AppBarButton from "./AppBarButton";
import DrawerNavItem from "./DrawerNavItem";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import useSnack from "./ProvideSnack";
import DesktopUserDisplay from "./UserAvatar";

const drawerWidth = 240;

interface NavigationProps {
    pages: PageList[];
    handleOpenProfile: () => void;
}

const Navigation: FC<NavigationProps> = (props: NavigationProps) => {
    const { pages, handleOpenProfile } = props;
    const snack = useSnack();
    const user = useUser();
    const navigate = useNavigate();
    const { isAuthenticated, user: authUser } = useAuth0();
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const loginUser = async () => {
        const response = await user_login(authUser as Auth0User);
        if (response.status === "success" && response.data) {
            snack("success", `Welcome ${response.data.name}`);
            user.setProfile(response.data);
        } else {
            snack("error", "Error logging in. Message" + response.message);
        }
    };

    const logoutUser = async () => {
        user.setProfile(userProfileDefaultValue);
    };

    useEffect(() => {
        if (isAuthenticated) {
            loginUser();
        } else {
            logoutUser();
            sessionStorage.removeItem("profile");
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (user && user.profile.id) {
            const session = sessionStorage.getItem("profile");
            if (!user.profile.phone && session !== "checked") {
                handleOpenProfile();
            }
        }
    }, [user]);

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar component="nav" sx={{ backgroundColor: "#ffffff" }}>
                <Toolbar>
                    <IconButton
                        size="medium"
                        color="primary"
                        aria-label="open drawer"
                        edge="start"
                        sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon sx={{ fontSize: 28 }} />
                    </IconButton>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                            justifyContent: { xs: "center", md: "flex-start" },
                        }}
                    >
                        <Box
                            component="img"
                            onClick={() => navigate("/home")}
                            src={TenBackLogoWide}
                            sx={{ maxHeight: 50, cursor: "pointer" }}
                        />
                    </Box>
                    <Box sx={{ display: { xs: "none", md: "flex" }, flexDirection: "row" }}>
                        {pages
                            .filter((page) => page.displayInNav === true)
                            .map((page) => (
                                <AppBarButton key={`appbar-${page.name}`} page={page} />
                            ))}
                        {isAuthenticated ? (
                            <DesktopUserDisplay handleOpenProfile={handleOpenProfile} />
                        ) : (
                            <LoginButton />
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={drawerOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                    }}
                >
                    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
                        <Typography variant="h6" sx={{ my: 2 }}>
                            TenBack
                        </Typography>
                        <Divider />
                        <List>
                            {pages
                                .filter((page) => page.displayInNav === true)
                                .map((page) => (
                                    <DrawerNavItem key={`drawer-${page.name}`} page={page} />
                                ))}

                            <Divider />
                            {isAuthenticated ? (
                                <>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar src={authUser?.picture}>{authUser?.name?.slice(0, 2)}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={authUser?.name} />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={handleOpenProfile}>
                                            <ListItemText primary={"Your Profile"} />
                                        </ListItemButton>
                                    </ListItem>
                                    {user.profile.admin && (
                                        <ListItem disablePadding>
                                            <ListItemButton component={Link} to={"/admin"}>
                                                <ListItemText primary={"Admin Page"} />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    <ListItem sx={{ display: "flex", justifyContent: "center" }}>
                                        <LogoutButton />
                                    </ListItem>
                                </>
                            ) : (
                                <ListItem sx={{ display: "flex", justifyContent: "center" }}>
                                    <LoginButton />
                                </ListItem>
                            )}
                        </List>
                    </Box>
                </Drawer>
            </Box>
        </Box>
    );
};

export default Navigation;
