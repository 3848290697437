import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, InputBase } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC, useState } from "react";

interface SearchBarProps {
    searchFn: (text?: string) => void;
}

const SearchBar: FC<SearchBarProps> = (props: SearchBarProps) => {
    const { searchFn } = props;
    const theme = useTheme();
    const [text, setText] = useState<string>("");

    const handleClear = () => {
        setText("");
        searchFn();
    };

    const handleSearch = () => searchFn(text);

    return (
        <Box
            component="form"
            sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 300,
                backgroundColor: theme.palette.background.paper,
                border: "1px solid",
                borderRadius: 1,
                borderColor: theme.palette.background.default,
            }}
        >
            {text ? (
                <IconButton onClick={handleClear} aria-label="clear button">
                    <ClearIcon />
                </IconButton>
            ) : (
                <Box sx={{ height: 40, width: 40 }} />
            )}
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                value={text}
                onChange={(e) => setText(e.target.value)}
                inputProps={{
                    "aria-label": "search textbox",
                }}
            />
            <IconButton type="button" aria-label="search button" onClick={handleSearch}>
                <SearchIcon />
            </IconButton>
        </Box>
    );
};

export default SearchBar;
