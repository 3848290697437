import { Card, CardContent } from "@mui/material";
import { FC } from "react";
import ReactMarkdown from "react-markdown";

interface TopCardProps {
    text: string;
}

const TopCard: FC<TopCardProps> = (props: TopCardProps) => {
    const { text } = props;
    return (
        <Card>
            <CardContent>
                <ReactMarkdown>{text}</ReactMarkdown>
            </CardContent>
        </Card>
    );
};

export default TopCard;
