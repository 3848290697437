import { API_URL } from "config/api";
import { Hours, HoursUpdate } from "interface/hours.interface";

export const HOURS_API = `${API_URL}/hours`;

export const hours_getAll = async (): Promise<{ status: string; data?: Hours[]; message?: string }> => {
    const response = await fetch(`${HOURS_API}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const hours_getActive = async (): Promise<{ status: string; data?: Hours; message?: string }> => {
    const response = await fetch(`${HOURS_API}/active`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const hours_getById = async (id: string): Promise<{ status: string; data?: Hours; message?: string }> => {
    const response = await fetch(`${HOURS_API}/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const hours_post = async (newDocument: Hours): Promise<{ status: string; data?: Hours; message?: string }> => {
    const response = await fetch(`${HOURS_API}`, {
        method: "POST",
        body: JSON.stringify(newDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const hours_patch = async (
    updatedDocument: HoursUpdate,
): Promise<{ status: string; data?: [number]; message: string }> => {
    const response = await fetch(`${HOURS_API}`, {
        method: "PATCH",
        body: JSON.stringify(updatedDocument),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const hours_activate = async (id: string): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${HOURS_API}/activate`, {
        method: "PATCH",
        body: JSON.stringify({ id: id }),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};

export const hours_delete = async (id: string): Promise<{ status: string; data?: number[]; message?: string }> => {
    const response = await fetch(`${HOURS_API}`, {
        method: "DELETE",
        body: JSON.stringify({ id: id }),
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-store, no-cache, must-revalidate",
        },
    });
    return response.json();
};
