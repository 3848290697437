import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { hours_getActive } from "api/hours.api";
import { handleGetDayHoursTableCell } from "functions/hours.func";
import { Hours } from "interface/hours.interface";
import { FC, useEffect, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";

const HoursTable: FC = () => {
    const [hours, setHours] = useState<Hours | null>(null);

    const snack = useSnack();

    const handleGetHours = async () => {
        const response = await hours_getActive();
        if (response.status === "success") {
            setHours(response.data!);
        } else {
            snack("error", "Error getting Hours. " + response.message!);
        }
    };

    useEffect(() => {
        handleGetHours();
    }, []);

    return (
        hours && (
            <Table>
                <TableBody>
                    <TableRow id="monday">
                        <TableCell component="th">Monday</TableCell>
                        {handleGetDayHoursTableCell(hours.mondayClosed, hours.mondayOpen, hours.mondayClose)}
                    </TableRow>
                    <TableRow id="tuesday">
                        <TableCell component="th">Tuesday</TableCell>
                        {handleGetDayHoursTableCell(hours.tuesdayClosed, hours.tuesdayOpen, hours.tuesdayClose)}
                    </TableRow>
                    <TableRow id="wednesday">
                        <TableCell component="th">Wednesday</TableCell>
                        {handleGetDayHoursTableCell(hours.wednesdayClosed, hours.wednesdayOpen, hours.wednesdayClose)}
                    </TableRow>
                    <TableRow id="thursday">
                        <TableCell component="th">Thursday</TableCell>
                        {handleGetDayHoursTableCell(hours.thursdayClosed, hours.thursdayOpen, hours.thursdayClose)}
                    </TableRow>
                    <TableRow id="friday">
                        <TableCell component="th">Friday</TableCell>
                        {handleGetDayHoursTableCell(hours.fridayClosed, hours.fridayOpen, hours.fridayClose)}
                    </TableRow>
                    <TableRow id="saturday">
                        <TableCell component="th">Saturday</TableCell>
                        {handleGetDayHoursTableCell(hours.saturdayClosed, hours.saturdayOpen, hours.saturdayClose)}
                    </TableRow>
                    <TableRow id="sunday">
                        <TableCell component="th">Sunday</TableCell>
                        {handleGetDayHoursTableCell(hours.sundayClosed, hours.sundayOpen, hours.sundayClose)}
                    </TableRow>
                </TableBody>
            </Table>
        )
    );
};

export default HoursTable;
