import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
    Box,
    Button,
    IconButton,
    Paper,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { OrderPopulated } from "interface/order.interface";
import { FC, MouseEvent, useState } from "react";
import QuickMenu from "../components/QuickMenu";

interface Props {
    orders: OrderPopulated[];
    edit: (order: OrderPopulated) => void;
    deleteItem: (id: string) => void;
    update: () => void;
}

const OrderTableView: FC<Props> = (props: Props) => {
    const { orders, edit, deleteItem, update } = props;
    const [popper, setPopper] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentStatus, setCurrentStatus] = useState<string>("");
    const [selectedId, setSelectedId] = useState<string>("");

    const handleOpenPopper = (e: MouseEvent<HTMLButtonElement>, text: string) => {
        setAnchorEl(e.currentTarget);
        setPopper(text);
    };

    const handleClosePopper = () => {
        setAnchorEl(null);
        setPopper("");
    };

    const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>, status: string, id: string) => {
        setAnchorEl(e.currentTarget);
        setCurrentStatus(status);
        setSelectedId(id);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentStatus("");
    };

    return (
        <Box
            sx={{
                maxWidth: "100%",
                maxHeight: 1000,
            }}
        >
            <TableContainer component={Paper}>
                <Table sx={{ whiteSpace: "nowrap" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell>Size</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Supplier</TableCell>
                            <TableCell>Other Details</TableCell>
                            <TableCell>Paid</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Ordered Date</TableCell>
                            <TableCell>Received Date</TableCell>
                            <TableCell>Picked Up Date</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Archived</TableCell>
                            <TableCell>Last Updated Date</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Last Updated By</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order) => (
                            <TableRow key={order.id}>
                                <TableCell>
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <IconButton onClick={() => edit(order)} size="small">
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleOpenMenu(e, order.status, order.id)}
                                        >
                                            <EditNoteIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton onClick={() => deleteItem(order.id)} size="small">
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        color: order.paid ? "inherit" : "red",
                                    }}
                                >
                                    {order.status}
                                </TableCell>
                                <TableCell>{order.User.name}</TableCell>
                                <TableCell>{order.itemCustom}</TableCell>
                                <TableCell>{order.size}</TableCell>
                                <TableCell>{order.quantity}</TableCell>
                                <TableCell>{order.supplier}</TableCell>
                                <TableCell>
                                    {order.otherDesc ? (
                                        <Button size="small" onClick={(e) => handleOpenPopper(e, order.otherDesc)}>
                                            View
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </TableCell>
                                <TableCell sx={{ color: order.paid ? "inherit" : "red" }}>
                                    {order.paid ? "Yes" : "No"}
                                </TableCell>
                                <TableCell>
                                    {order.createdAt ? dayjs(order.createdAt).format("MM-DD-YYYY") : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {order.orderedDate ? dayjs(order.orderedDate).format("MM-DD-YYYY") : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {order.receivedDate ? dayjs(order.receivedDate).format("MM-DD-YYYY") : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {order.pickedUpDate ? dayjs(order.pickedUpDate).format("MM-DD-YYYY") : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {order.notes ? (
                                        <Button size="small" onClick={(e) => handleOpenPopper(e, order.notes)}>
                                            View
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </TableCell>
                                <TableCell>{order.archived ? "Yes" : "No"}</TableCell>
                                <TableCell>
                                    {order.updatedAt ? dayjs(order.updatedAt).format("MM-DD-YYYY") : "N/A"}
                                </TableCell>
                                <TableCell>{order.EnteredBy?.name}</TableCell>
                                <TableCell>{order.UpdatedBy?.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopper}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Typography sx={{ p: 1, maxWidth: 400 }} variant="body2">
                    {popper}
                </Typography>
            </Popover>
            <QuickMenu
                anchorEl={anchorEl}
                close={handleCloseMenu}
                currentStatus={currentStatus}
                id={selectedId}
                update={update}
            />
        </Box>
    );
};

export default OrderTableView;
