import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Avatar, Box, Card, useTheme } from "@mui/material";
import { FC } from "react";
import ReactMarkdown from "react-markdown";

interface AnnouncementProps {
    title?: string;
    text: string;
}

const AnnouncementCard: FC<AnnouncementProps> = (props: AnnouncementProps) => {
    const theme = useTheme();
    const { text } = props;
    return (
        <Card sx={{ mb: theme.spacing(1), backgroundColor: theme.palette.primary.main, color: "white" }}>
            <Box sx={{ display: "flex", p: theme.spacing(2), maxWidth: 800, margin: "auto" }}>
                <Box sx={{ mr: theme.spacing(2) }}>
                    <Avatar>
                        <AnnouncementIcon color="secondary" />
                    </Avatar>
                </Box>
                <Box>
                    <ReactMarkdown>{text}</ReactMarkdown>
                </Box>
            </Box>
        </Card>
    );
};

export default AnnouncementCard;
