import "@mdxeditor/editor/style.css";
import AddIcon from "@mui/icons-material/Add";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Box,
    Button,
    CardActions,
    CardContent,
    Divider,
    FormControlLabel,
    IconButton,
    Card as MuiCard,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MDEditor from "@uiw/react-md-editor";
import { card_getAvailable, card_move_patch, card_patch } from "api/card.api";
import { page_getByPath, page_patch } from "api/page.api";
import CancelButton from "components/CancelButton";
import SaveButton from "components/SaveButton";
import { Card, CardMove, CardUpdate } from "interface/card.interface";
import { PagePopulated, PageUpdate } from "interface/page.interface";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSnack from "routes/common/components/ProvideSnack";
import EditCard from "./EditCard";
import { useGetPages } from "./Pages";
import ViewCard from "./ViewCard";

const EditPage: FC = () => {
    const [page, setPage] = useState<PagePopulated | null>(null);
    const [changes, setChanges] = useState<boolean>(false);

    const [pageName, setPageName] = useState<string>("");
    const [order, setOrder] = useState<number>(0);
    const [display, setDisplay] = useState<boolean>(false);
    const [pagePath, setPagePath] = useState<string>("");
    const [announcmentTitle, setAnnouncementTitle] = useState<string>("");
    const [announcmentText, setAnnouncementText] = useState<string>("");
    const [banner, setBanner] = useState<string>("");

    const [cards, setCards] = useState<Card[]>([]);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openView, setOpenView] = useState<boolean>(false);
    const [selectedCard, setSelectedCard] = useState<Card | null>(null);
    const [availableCards, setAvailableCards] = useState<Card[]>([]);

    const { path } = useParams();
    const snack = useSnack();
    const theme = useTheme();
    const navigate = useNavigate();
    const getPages = useGetPages();

    const handleGetPage = async () => {
        if (path) {
            const response = await page_getByPath(path);
            if (response.status === "success" && response.data) {
                setPage(response.data);
                setPageName(response.data.name);
                setOrder(response.data.order || 0);
                setDisplay(response.data.displayInNav);
                setPagePath(response.data.path);
                setAnnouncementTitle(response.data.announcementTitle || "");
                setAnnouncementText(response.data.announcementText || "");
                setCards(response.data.Cards || []);
                setBanner(response.data.bannerText || "");
            } else {
                snack("error", response.status + ": " + response.message);
                return;
            }

            const response2 = await card_getAvailable(response.data!.id);
            if (response2.status === "success") {
                setAvailableCards(response2.data!);
            } else {
                snack("error", response.status + ": " + response.message);
            }
        }
    };

    const handleCancel = () => {
        if (page) {
            setPageName(page.name);
            page.order && setOrder(page.order);
            setDisplay(page.displayInNav);
            setPagePath(page.path);
            page.announcementTitle && setAnnouncementTitle(page.announcementTitle);
            page.announcementText && setAnnouncementText(page.announcementText);
        }
        setChanges(false);
    };

    const handleSave = async () => {
        const data: PageUpdate = {
            id: page?.id as string,
            name: pageName,
            order: order,
            displayInNav: display,
            path: pagePath,
            announcementText: announcmentText,
            announcementTitle: announcmentTitle,
            bannerText: banner,
        };
        const response = await page_patch(data);
        if (response.status === "success" && response.data![0] === 1) {
            snack("success", "Page successfully updated.");
            getPages.handleGetPages();
            setChanges(false);
            navigate("/admin/pages/" + pagePath);
        } else {
            snack("error", response.status + ": " + response.message);
        }
    };

    const handleAddNewCard = () => {
        setSelectedCard(null);
        setOpenEdit(true);
    };

    const handleEditCard = (index: number, set: "onPage" | "available") => {
        if (set === "onPage") {
            setSelectedCard(cards[index]);
        } else {
            setSelectedCard(availableCards[index]);
        }
        setOpenEdit(true);
    };

    const handleViewCard = (index: number, set: "onPage" | "available") => {
        if (set === "onPage") {
            setSelectedCard(cards[index]);
        } else {
            setSelectedCard(availableCards[index]);
        }
        setOpenView(true);
    };

    const handleCloseDialog = () => {
        setSelectedCard(null);
        setOpenEdit(false);
        setOpenView(false);
    };

    const handleUpdateName = (newName: string) => {
        setPageName(newName);
        setChanges(true);
        const newPath = newName.toLowerCase().replace(/ /g, "-");
        setPagePath(newPath);
    };

    const handleUpdateOrder = (newOrder: number) => {
        setOrder(newOrder);
        setChanges(true);
    };

    const handleUpdateDisplay = (checked: boolean) => {
        setDisplay(checked);
        setChanges(true);
    };

    // const handleUpdateAnnouncementTitle = (value: string) => {
    //     setAnnouncementTitle(value);
    //     setChanges(true);
    // };

    const handleUpdateAnnouncementText = (value: string) => {
        setAnnouncementText(value);
        setChanges(true);
    };

    const handleUpdateBanner = (value: string) => {
        setBanner(value);
        setChanges(true);
    };

    const handleAddCardToPage = async (id: string) => {
        const data: CardUpdate = {
            id: id,
            PageId: page!.id,
            order: cards.length + 1,
        };
        const response = await card_patch(data);
        if (response.status === "failed") {
            snack("error", response.status + ": " + response.message);
            return;
        }
        handleGetPage();
    };

    const handleMoveCard = async (id: string, direction: "up" | "down" | "remove", order: number) => {
        const data: CardMove = {
            id: id,
            PageId: page!.id,
            direction: direction,
            order: order,
        };
        const response = await card_move_patch(data);
        if (response.status === "failed") {
            snack("error", "Error moving card: " + response.message);
            return;
        }
        handleGetPage();
    };

    useEffect(() => {
        handleGetPage();
    }, [path]);

    return (
        page && (
            <Box>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography variant="h4" sx={{ mb: theme.spacing(2) }}>
                        Page Settings
                    </Typography>
                    <Box sx={{ display: changes ? "flex" : "none" }}>
                        <CancelButton onClick={handleCancel} />
                        <SaveButton onClick={handleSave} />
                    </Box>
                </Box>
                <Box sx={{ ml: theme.spacing(3), mr: theme.spacing(3) }}>
                    <TextField
                        label="Name"
                        variant="standard"
                        value={pageName}
                        onChange={(e) => handleUpdateName(e.target.value)}
                        sx={{ mb: theme.spacing(2) }}
                    />
                    <Typography variant="subtitle2" sx={{ mb: theme.spacing(2) }}>
                        Path: {pagePath}
                    </Typography>
                    <TextField
                        label="Order"
                        type="number"
                        variant="standard"
                        sx={{ mb: theme.spacing(2) }}
                        value={order}
                        onChange={(e) => handleUpdateOrder(Number(e.target.value))}
                    />
                    <br />
                    <FormControlLabel
                        control={<Switch checked={display} onChange={(e) => handleUpdateDisplay(e.target.checked)} />}
                        label="Display in Navigation Bar"
                        sx={{ mb: theme.spacing(2) }}
                    />
                    {/* <TextField
                        label="Announcment Title"
                        variant="standard"
                        fullWidth
                        value={announcmentTitle}
                        onChange={(e) => handleUpdateAnnouncementTitle(e.target.value)}
                        sx={{ mb: theme.spacing(2) }}
                    /> */}
                    <Typography variant="body2">Announcement Text</Typography>
                    <MDEditor
                        value={announcmentText}
                        onChange={(value) => handleUpdateAnnouncementText(value as string)}
                        height="150px"
                        preview="edit"
                    />
                    <Typography variant="body2" sx={{ mt: theme.spacing(2) }}>
                        Banner
                    </Typography>
                    <MDEditor
                        value={banner}
                        onChange={(value) => handleUpdateBanner(value as string)}
                        height="200px"
                        preview="edit"
                    />
                </Box>
                <Divider sx={{ m: theme.spacing(2) }} />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h4" sx={{ mb: theme.spacing(2) }}>
                        Cards
                    </Typography>
                    <Button variant="contained" onClick={handleAddNewCard} startIcon={<AddIcon />}>
                        New Card
                    </Button>
                </Box>
                <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                    <Box>
                        <Typography variant="h5">Cards on Page</Typography>
                        <Box
                            sx={{
                                maxHeight: 600,
                                width: 350,
                                overflow: "auto",
                            }}
                        >
                            {cards
                                .sort((a, b) => (a.order as number) - (b.order as number))
                                .map((card, index) => (
                                    <MuiCard variant="outlined" key={card.id} sx={{ mb: theme.spacing(1), width: 250 }}>
                                        <CardContent sx={{ height: 75 }}>
                                            <Typography variant="h6">{card.title}</Typography>
                                            <Typography variant="body2">{card.subtitle}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Tooltip title="View">
                                                <IconButton
                                                    onClick={() => handleViewCard(index, "onPage")}
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <IconButton
                                                    onClick={() => handleEditCard(index, "onPage")}
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Remove">
                                                <IconButton
                                                    onClick={() =>
                                                        handleMoveCard(card.id, "remove", card.order as number)
                                                    }
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Move Up">
                                                <IconButton
                                                    disabled={card.order === 1}
                                                    onClick={() => handleMoveCard(card.id, "up", card.order as number)}
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <ArrowUpwardIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Move Down">
                                                <IconButton
                                                    disabled={card.order === cards.length}
                                                    onClick={() =>
                                                        handleMoveCard(card.id, "down", card.order as number)
                                                    }
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <ArrowDownwardIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </CardActions>
                                    </MuiCard>
                                ))}
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h5">Page Only Cards</Typography>

                        <Box
                            sx={{
                                maxHeight: 600,
                                width: 350,
                                overflow: "auto",
                            }}
                        >
                            {availableCards
                                .filter((card) => card.pageOnlyId === page.id)
                                .map((card, index) => (
                                    <MuiCard variant="outlined" key={card.id} sx={{ mb: theme.spacing(1), width: 250 }}>
                                        <CardContent sx={{ height: 75 }}>
                                            <Typography variant="h6">{card.title}</Typography>
                                            <Typography variant="body2">{card.subtitle}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Tooltip title="View">
                                                <IconButton
                                                    onClick={() => handleViewCard(index, "available")}
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Add">
                                                <IconButton
                                                    onClick={() => handleAddCardToPage(card.id)}
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <IconButton
                                                    onClick={() => handleEditCard(index, "available")}
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </CardActions>
                                    </MuiCard>
                                ))}
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h5">Available Cards</Typography>

                        <Box
                            sx={{
                                maxHeight: 600,
                                width: 350,
                                overflow: "auto",
                            }}
                        >
                            {availableCards
                                .filter((card) => card.pageOnlyId !== page.id)
                                .map((card, index) => (
                                    <MuiCard variant="outlined" key={card.id} sx={{ mb: theme.spacing(1), width: 250 }}>
                                        <CardContent sx={{ height: 75 }}>
                                            <Typography variant="h6">{card.title}</Typography>
                                            <Typography variant="body2">{card.subtitle}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Tooltip title="View">
                                                <IconButton
                                                    onClick={() => handleViewCard(index, "available")}
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Add">
                                                <IconButton
                                                    onClick={() => handleAddCardToPage(card.id)}
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <IconButton
                                                    onClick={() => handleEditCard(index, "available")}
                                                    sx={{ fontSize: 24 }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </CardActions>
                                    </MuiCard>
                                ))}
                        </Box>
                    </Box>
                </Box>
                <EditCard
                    card={selectedCard}
                    open={openEdit}
                    mode={selectedCard ? "edit" : "add"}
                    currentPageId={page.id}
                    handleClose={handleCloseDialog}
                    updateCards={handleGetPage}
                    cardCount={cards.length}
                />
                <ViewCard card={selectedCard} open={openView} handleClose={handleCloseDialog} />
            </Box>
        )
    );
};

export default EditPage;
