import {
    Box,
    Button,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Card as MuiCard,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import parse from "html-react-parser";
import { Card } from "interface/card.interface";
import React, { FC, useState } from "react";
import ReactMarkdown from "react-markdown";
import { formatUS } from "routes/common/functions/currency";

interface ViewCardProps {
    card: Card | null;
    open: boolean;
    handleClose: () => void;
}

const ViewCard: FC<ViewCardProps> = (props: ViewCardProps) => {
    const { card, open, handleClose } = props;
    const theme = useTheme();

    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const toggleOpenDialog = () => {
        setOpenDialog(!openDialog);
    };

    return (
        card && (
            <Dialog open={open} onClose={handleClose}>
                <MuiCard
                    sx={{
                        minHeight: 144,
                        m: theme.spacing(1),
                        width: 350,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {card.image ? (
                        <CardMedia
                            src={card.image}
                            // title={card.imageTitle}
                            component="img"
                            sx={{ maxHeight: 175, maxWidth: 175 }}
                        />
                    ) : null}
                    <CardContent sx={{ textAlign: "center" }}>
                        <Typography variant="h6">{card.title}</Typography>
                        <Typography variant="body1">{card.subtitle}</Typography>
                        {card.price ? (
                            card.salePrice ? (
                                <Box sx={{ mt: theme.spacing(1) }}>
                                    <Typography variant="body1" color="error">
                                        Sale! {formatUS(card.salePrice)}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ fontDecoration: "strike-through", color: theme.palette.text.disabled }}
                                    >
                                        {formatUS(card.price)}
                                    </Typography>
                                </Box>
                            ) : (
                                <Box sx={{ mt: theme.spacing(1), textAlign: "center" }}>
                                    <Typography variant="body1">{formatUS(card.price)}</Typography>
                                </Box>
                            )
                        ) : null}
                        {card.priceOverride ? (
                            <Box sx={{ mt: theme.spacing(1), textAlign: "center" }}>
                                <Typography variant="body1">{parse(card.priceOverride)}</Typography>
                            </Box>
                        ) : null}
                    </CardContent>
                    {card.details ? (
                        <React.Fragment>
                            <CardActions sx={{ justifyContent: "center" }}>
                                <Button size="small" color="primary" onClick={toggleOpenDialog}>
                                    View Details
                                </Button>
                            </CardActions>
                            <Dialog open={openDialog} onClose={toggleOpenDialog}>
                                <DialogTitle>{card.title}</DialogTitle>
                                <DialogContent>
                                    <ReactMarkdown>{card.details}</ReactMarkdown>
                                </DialogContent>
                                <DialogActions>
                                    <Button size="small" variant="contained" onClick={toggleOpenDialog}>
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    ) : null}
                </MuiCard>
            </Dialog>
        )
    );
};

export default ViewCard;
