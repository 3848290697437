import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import PageBanner from "routes/common/components/PageBanner";

const Equipment: FC = () => {
    return (
        <Box>
            <PageBanner image={"shop"} />
            <Typography variant="h6">Page Under Construction</Typography>
        </Box>
    );
};

export default Equipment;
