import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";

const LogoutButton: FC = () => {
    const { logout } = useAuth0();

    const handleLogout = () => logout();

    return (
        <Button variant="contained" color="secondary" onClick={handleLogout}>
            Log Out
        </Button>
    );
};

export default LogoutButton;
