import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    InputLabel,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { user_patch, user_post } from "api/user.api";
import dayjs from "dayjs";
import { User, UserCreate, UserUpdate } from "interface/user.interface";
import { FC, useEffect, useMemo, useState } from "react";
import useSnack from "routes/common/components/ProvideSnack";

interface EditUserProps {
    user: User | null;
    mode: "add" | "edit";
    open: boolean;
    handleClose: () => void;
    updateUsers: () => void;
}

const EditUser: FC<EditUserProps> = (props: EditUserProps) => {
    const { user, mode, open, handleClose, updateUsers } = props;
    const theme = useTheme();
    const snack = useSnack();

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [admin, setAdmin] = useState<boolean>(false);
    const [birthday, setBirthday] = useState<dayjs.Dayjs | null>(null);
    const [areaCode, setAreaCode] = useState<string>("");
    const [phone1, setPhone1] = useState<string>("");
    const [phone2, setPhone2] = useState<string>("");
    const [textMsgs, setTextMsgs] = useState<boolean>(true);

    const allowSave = useMemo(() => {
        if (
            name &&
            email &&
            areaCode &&
            phone1 &&
            phone2 &&
            areaCode.length === 3 &&
            phone1.length === 3 &&
            phone2.length === 4
        )
            return true;
        else return false;
    }, [name, areaCode, phone1, phone2]);

    const changes = useMemo(() => {
        if (name !== user?.name) return true;
        if (email !== user?.email) return true;
        if (areaCode + "-" + phone1 + "-" + phone2 !== user?.phone) return true;
        if (textMsgs !== user?.textMsgs) return true;
        if (birthday !== dayjs(user?.birthday)) return true;
        else return false;
    }, [name, areaCode, phone1, phone2, textMsgs, birthday]);

    const handleLoad = () => {
        setName(user?.name ?? "");
        setEmail(user?.email ?? "");
        setBirthday(user ? dayjs(user?.birthday) : null);
        if (user?.phone) {
            const phone = user.phone.split("-");
            setAreaCode(phone[0]);
            setPhone1(phone[1]);
            setPhone2(phone[2]);
        }
        setAdmin(user?.admin ?? false);
        setTextMsgs(user?.textMsgs ?? false);
    };

    const handleCancelAndClose = () => {
        handleLoad();
        handleClose();
    };

    const handleChangeAreaCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        const clean = text.replace(/\D/g, "").trim().slice(0, 3);
        setAreaCode(clean);
        if (text.length === 3) {
            document.getElementById("phone-2")?.focus();
        }
    };

    const handleChangePhone1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        const clean = text.replace(/\D/g, "").trim().slice(0, 3);
        setPhone1(clean);
        if (text.length === 3) {
            document.getElementById("phone-3")?.focus();
        } else if (text.length === 0) {
            document.getElementById("phone-1")?.focus();
        }
    };

    const handleChangePhone2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        const clean = text.replace(/\D/g, "").trim().slice(0, 4);
        setPhone2(clean);
        if (text.length === 4) {
            document.getElementById("text-allow")?.focus();
        } else if (text.length === 0) {
            document.getElementById("phone-2")?.focus();
        }
    };

    const handleSubmit = async () => {
        if (mode === "add") {
            const data: UserCreate = {
                name: name,
                email: email,
                admin: admin,
                birthday: birthday?.toString(),
                phone: areaCode ? areaCode + "-" + phone1 + "-" + phone2 : "",
                textMsgs: textMsgs,
            };
            const response1 = await user_post(data);
            if (response1.status === "success") {
                snack("success", "Successfully added new user.");
                updateUsers();
                handleCancelAndClose();
            } else {
                snack("error", "Error adding user: " + response1.message);
            }
        } else {
            const update: UserUpdate = {
                id: user?.id as string,
                name: name,
                email: email,
                admin: admin,
                birthday: birthday?.toString(),
                phone: areaCode ? areaCode + "-" + phone1 + "-" + phone2 : "",
                textMsgs: textMsgs,
            };
            const response2 = await user_patch(update);
            if (response2.status === "success") {
                snack("success", "Successfully updated user.");
                updateUsers();
                handleCancelAndClose();
            } else {
                snack("error", "Error updating user: " + response2.message);
            }
        }
    };

    useEffect(() => {
        handleLoad();
    }, [user]);

    return (
        <Dialog open={open}>
            <IconButton onClick={handleCancelAndClose} size="large" sx={{ position: "absolute", right: 0, top: 0 }}>
                <CloseIcon color="error" sx={{ fontSize: 24 }} />
            </IconButton>
            <DialogTitle>{mode === "add" ? "Add" : "Edit"} User</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", mt: theme.spacing(2) }}>
                <TextField
                    fullWidth
                    required
                    label="Name"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    sx={{ mt: 1 }}
                    error={name.length < 1}
                />
                <TextField
                    fullWidth
                    required
                    label="Email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    disabled={mode === "edit"}
                    sx={{ mt: theme.spacing(2) }}
                    error={email.length < 1 || !email.includes("@") || !email.includes(".")}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        sx={{ mt: theme.spacing(2) }}
                        label="Birthday (optional)"
                        value={birthday}
                        onChange={(newValue) => {
                            setBirthday(newValue);
                        }}
                    />
                </LocalizationProvider>
                <InputLabel required sx={{ mt: theme.spacing(2) }}>
                    Phone Number
                </InputLabel>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography fontSize={24}>(</Typography>
                    <TextField
                        id="phone-1"
                        size="small"
                        value={areaCode}
                        onChange={handleChangeAreaCode}
                        sx={{ width: 60, m: theme.spacing(1) }}
                        error={areaCode.length < 3}
                    />
                    <Typography fontSize={24}>)</Typography>
                    <TextField
                        id="phone-2"
                        size="small"
                        value={phone1}
                        onChange={handleChangePhone1}
                        sx={{ width: 60, m: theme.spacing(1) }}
                        error={phone1.length < 3}
                    />
                    <Typography fontSize={24}>-</Typography>
                    <TextField
                        id="phone-3"
                        size="small"
                        value={phone2}
                        onChange={handleChangePhone2}
                        sx={{ width: 80, m: theme.spacing(1) }}
                        error={phone2.length < 4}
                    />
                </Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="text-allow"
                            checked={textMsgs}
                            onChange={(e) => {
                                setTextMsgs(e.target.checked);
                            }}
                        />
                    }
                    label="Allow text notifications"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={admin}
                            onChange={(e) => {
                                setAdmin(e.target.checked);
                            }}
                        />
                    }
                    label="Site Admin"
                    sx={{ mt: theme.spacing(2) }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancelAndClose}>
                    Cancel
                </Button>
                <Button disabled={!allowSave || !changes} variant="contained" onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUser;
