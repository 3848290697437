import {
    Box,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useUser } from "hooks/User";
import { FC, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const Admin: FC = () => {
    const user = useUser();
    const navigate = useNavigate();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    return user.profile.admin ? (
        <Box sx={{ p: mobile ? 1 : theme.spacing(2) }}>
            <Typography variant={mobile ? "h4" : "h1"}>
                Admin Panel <em>- {user.profile.name}</em>
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "center", sm: "flex-start" },
                    mt: theme.spacing(1),
                }}
            >
                <Paper sx={{ width: { xs: "auto", sm: 200 }, mb: theme.spacing(2) }}>
                    <List sx={{ display: { xs: "flex", sm: "block" } }}>
                        <ListItem disablePadding>
                            <ListItemButton
                                selected={selectedIndex === 0}
                                onClick={() => {
                                    navigate("/admin/orders");
                                    setSelectedIndex(0);
                                }}
                            >
                                <ListItemText>Orders</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                selected={selectedIndex === 1}
                                onClick={() => {
                                    navigate("/admin/pages");
                                    setSelectedIndex(1);
                                }}
                            >
                                <ListItemText>Pages</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                selected={selectedIndex === 2}
                                onClick={() => {
                                    navigate("/admin/users");
                                    setSelectedIndex(2);
                                }}
                            >
                                <ListItemText>Users</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                selected={selectedIndex === 3}
                                onClick={() => {
                                    navigate("/admin/hours");
                                    setSelectedIndex(3);
                                }}
                            >
                                <ListItemText>Hours</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                selected={selectedIndex === 4}
                                onClick={() => {
                                    navigate("/admin/footer-links");
                                    setSelectedIndex(4);
                                }}
                            >
                                <ListItemText>Links</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                selected={selectedIndex === 4}
                                onClick={() => {
                                    navigate("/admin/patch-notes");
                                    setSelectedIndex(5);
                                }}
                            >
                                <ListItemText>Patch Notes</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Paper>
                <Paper
                    sx={{
                        ml: mobile ? "auto" : theme.spacing(2),
                        p: mobile ? 1 : theme.spacing(2),
                        width: { xs: "95%", sm: "80%" },
                        height: "100%",
                        minHeight: 800,
                        backgroundColor: "#ddd",
                    }}
                >
                    <Outlet />
                </Paper>
            </Box>
        </Box>
    ) : (
        <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography>You don&apos;t have permission to be on this page.</Typography>
            <Button onClick={() => navigate(-1)}>Go Back</Button>
        </Box>
    );
};

export default Admin;
