import { PageList } from "interface/page.interface";
import React, { FC } from "react";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

interface AppBarButtonProps {
    page: PageList;
}

const AppBarButton: FC<AppBarButtonProps> = (props: AppBarButtonProps) => {
    const { page } = props;
    const theme = useTheme();

    return (
        <Button
            key={page.name}
            component={Link}
            to={page.path}
            sx={{ color: theme.palette.text.primary, mr: theme.spacing(1) }}
        >
            {page.name}
        </Button>
    );
};

export default AppBarButton;
