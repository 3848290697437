import {
    Box,
    Button,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";
import { Card as CardI } from "interface/card.interface";
import React, { FC, useState } from "react";
import ReactMarkdown from "react-markdown";

interface BioCardProps {
    card: CardI;
}

const BioCard: FC<BioCardProps> = (props: BioCardProps) => {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);
    const { card } = props;

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <Paper
            sx={{
                m: theme.spacing(1),
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                p: theme.spacing(1),
            }}
        >
            {card.image ? (
                <CardMedia
                    src={card.image}
                    // title={card.imageTitle}
                    component="img"
                    sx={{ height: 175, width: 175, borderRadius: 2, pr: { xs: 0, sm: theme.spacing(2) } }}
                />
            ) : null}
            <Box
                sx={{
                    textAlign: "center",
                    height: 175,
                    width: { xs: "95%", sm: 200 },
                    p: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">{card.title}</Typography>
                <Divider sx={{ width: "75%", borderColor: "rgba(0, 0, 0, 0.5)" }} />
                <Typography variant="body1">{card.subtitle}</Typography>
                {card.details ? (
                    <React.Fragment>
                        <Box sx={{ justifyContent: "center" }}>
                            <Button size="small" color="primary" onClick={toggleOpen}>
                                View Bio
                            </Button>
                        </Box>
                        <Dialog open={open} onClose={toggleOpen}>
                            {card.image ? (
                                <Box
                                    component={"img"}
                                    src={card.image}
                                    sx={{
                                        maxHeight: 400,
                                        maxWidth: 400,
                                        borderRadius: 4,
                                        margin: "auto",
                                        mt: theme.spacing(2),
                                    }}
                                />
                            ) : null}
                            <DialogTitle>{card.title}</DialogTitle>
                            <DialogContent sx={{ minWidth: 400 }}>
                                <ReactMarkdown>{card.details}</ReactMarkdown>
                            </DialogContent>
                            <DialogActions>
                                <Button size="small" variant="contained" onClick={toggleOpen}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                ) : null}
            </Box>
        </Paper>
    );
};

export default BioCard;
