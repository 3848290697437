/* eslint-disable react/no-unknown-property */
import React, { FC } from "react";

interface FacebookPageProps {
    height: number;
    width: number;
}

const FacebookPage: FC<FacebookPageProps> = (props: FacebookPageProps) => {
    return (
        <div
            className="fb-page fb_iframe_widget"
            data-href="http://www.facebook.com/TenbackBowlingOutfitters"
            data-height={props.height + "px"}
            data-width={props.width + "px"}
            data-hide-cover="true"
            data-show-posts="true"
            data-show-facepile="false"
            data-adapt-container-width="true"
            fb-xfbml-state="rendered"
            fb-iframe-plugin-query={`adapt_container_width=true&amp;app_id=304553036307597&amp;container_width=${props.width}&amp;height=948&amp;hide_cover=true&amp;href=http%3A%2F%2Fwww.facebook.com%2FTenbackBowlingOutfitters&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=false&amp;show_posts=true&amp;width=${props.width}`}
        >
            <iframe
                name="f1603817a21984c"
                width={props.width + "px"}
                height={props.height + "px"}
                data-testid="fb:page Facebook Social Plugin"
                title="fb:page Facebook Social Plugin"
                frameBorder="0"
                allowTransparency={true}
                allowFullScreen={true}
                scrolling="no"
                allow="encrypted-media"
                src="https://www.facebook.com/v2.4/plugins/page.php?adapt_container_width=true&amp;app_id=304553036307597&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter.php%3Fversion%3D46%23cb%3Df4cfa2627cd7a8%26domain%3Dwww.tenbackbowling.com%26origin%3Dhttps%253A%252F%252Fwww.tenbackbowling.com%252Ff2fdcfab62c091c%26relation%3Dparent.parent&amp;container_width=350&amp;height=1075&amp;hide_cover=true&amp;href=http%3A%2F%2Fwww.facebook.com%2FTenbackBowlingOutfitters&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=false&amp;show_posts=true&amp;width=350"
            />
        </div>
    );
};

export default FacebookPage;
