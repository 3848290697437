import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import { FC } from "react";

interface SaveButtonProps {
    onClick: () => any;
}

const SaveButton: FC<SaveButtonProps> = (props: SaveButtonProps) => {
    const { onClick } = props;

    return (
        <Button variant="contained" onClick={onClick} startIcon={<SaveIcon />}>
            Save
        </Button>
    );
};

export default SaveButton;
