import {
    Box,
    Button,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";
import parse from "html-react-parser";
import { Card as CardI } from "interface/card.interface";
import React, { FC, useState } from "react";
import ReactMarkdown from "react-markdown";
import { formatUS } from "./../functions/currency";

interface ServiceCardProps {
    card: CardI;
}

const ServiceCard: FC<ServiceCardProps> = (props: ServiceCardProps) => {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);
    const { card } = props;

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <Paper
            sx={{
                m: theme.spacing(1),
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                p: theme.spacing(1),
            }}
        >
            {card.image ? (
                <CardMedia
                    src={card.image}
                    // title={card.imageTitle}
                    component="img"
                    sx={{ height: 175, width: 175, borderRadius: 2, pr: { xs: 0, sm: theme.spacing(2) } }}
                />
            ) : null}
            <Box
                sx={{
                    textAlign: "center",
                    height: 175,
                    width: { xs: "95%", sm: 200 },
                    p: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">{card.title}</Typography>
                {/* <Typography variant="body1">{card.subtitle}</Typography> */}
                {card.price ? (
                    card.salePrice ? (
                        <Box sx={{ mt: theme.spacing(1) }}>
                            <Typography variant="body1" color="error">
                                Sale! {formatUS(card.salePrice)}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ fontDecoration: "strike-through", color: theme.palette.text.disabled }}
                            >
                                {formatUS(card.price)}
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={{ mt: theme.spacing(1), textAlign: "center" }}>
                            <Typography variant="body1">{formatUS(card.price)}</Typography>
                        </Box>
                    )
                ) : null}
                {card.priceOverride ? (
                    <Box sx={{ mt: theme.spacing(1), textAlign: "center" }}>
                        <Typography variant="body1">{parse(card.priceOverride)}</Typography>
                    </Box>
                ) : null}
                {card.details ? (
                    <React.Fragment>
                        <Box sx={{ justifyContent: "center" }}>
                            <Button size="small" color="primary" onClick={toggleOpen}>
                                View Details
                            </Button>
                        </Box>
                        <Dialog open={open} onClose={toggleOpen}>
                            <DialogTitle>{card.title}</DialogTitle>
                            <DialogContent>
                                <ReactMarkdown>{card.details}</ReactMarkdown>
                            </DialogContent>
                            <DialogActions>
                                <Button size="small" variant="contained" onClick={toggleOpen}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                ) : null}
            </Box>
        </Paper>
    );
};

export default ServiceCard;
