import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { FC } from "react";

interface AddButtonProps {
    onClick: () => void;
    text?: string;
}

const AddButton: FC<AddButtonProps> = (props: AddButtonProps) => {
    const { onClick, text } = props;
    return (
        <Button variant="contained" startIcon={<AddIcon />} sx={{ width: 150 }} onClick={onClick}>
            {text ? text : "Add"}
        </Button>
    );
};

export default AddButton;
